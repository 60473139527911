import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ymicon from '@app/assets/images/ym.svg';
import { Noti } from '@app/components/Noti/Noti';
import { ymaDatas } from '@app/hocs/cache';

const AccessAllowPage: React.FC = () => {
  const { t } = useTranslation();
  const [message, setMessage] = React.useState<string>('사용자 인증을 하고 있습니다.');
  const ymaData = ymaDatas(); 

  useEffect(() => {
    if(ymaData.eqid === '') setMessage('관리자에게 사용 승인을 요청해 주세요.');
  }, [ymaData.eqid]);

  return (
    <>
      <PageTitle>{'사용자 인증'}</PageTitle>
      <Noti img={ymicon} msg={message} />
    </>
  );
};

export default AccessAllowPage;

// '관리자에게 사용 승인을 요청해 주세요.'