import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { ReactComponent as GoogleIcon } from '@app/assets/icons/google.svg';
import { ReactComponent as FacebookIcon } from '@app/assets/icons/facebook.svg';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { useLazyQuery, useMutation } from '@apollo/client';
import { generateUserM, getUserByName } from '@app/hocs/gql';
import { userDatas } from '@app/hocs/cache';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const initValues = {
  fullName: '',
  email: '',
  password: '',
  confirmPassword: '',
  termOfUse: true,
};

//const emailRegex = /^(([^<Fragment>()[\]\\.,;:\s@"]+(\.[^<Fragment>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [existUser, setExistUser] = useState(false); 
  const [validEmail, setValidEmail] = useState(false);  

  const { t } = useTranslation();

  const [generateUser , generateUserRequest] = useMutation(generateUserM , {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
    if(data.generateUserM.success === true ) {

      setMessage('');
      //setLogin( data.generateUserM.id === ''? 2:1);          
      //userDatas({...userDatas(), uid:data.generateUserM.id});
      //localStorage.setItem('exName' , email);
      //localStorage.setItem('exID' , data.generateUserM.id);
        //localStorage.setItem('userToken' , data.generateUserM.token);
    }
    else{
      setMessage(data.generateUserM.error);
    }
    }
  });

  const [ getUserByNameHandler, getUserByNameRequest] = useLazyQuery(getUserByName, {
    onCompleted: (data) => {
      if( data === undefined) return;
      if( data.getUserByName.user !== null ){
        setExistUser(true);
      } 
      else{
        setExistUser(false);
      } 
    }}
  )
  
  const checkEmail = (e:any) =>{
    const email:string = e.target.value;
    setExistUser(false);
    console.log("email:"+email);
    if( /^[A-Z0-9._%+-]+@[A-Z0-9{}.-]+\.[A-Z]{2,4}$/i.test(email) ){
      getUserByNameHandler({variables: {username: email},notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
        // pollInterval: 500
      });

      setValidEmail(true);
    }
    else{
      setValidEmail(false);
    }
  }
  
  const handleSubmit = (values: SignUpFormData) => {
    setLoading(true);

    const dataJson = {
      cases:[],
      actions:[["",{"cat":4,"type":10,"action":["../Form/User",""],"rules":""},1,""]], // cat:6(form) - type:1(assessment result)
      // @ts-ignore
      content:{name:values.fullName}, 
    }

    const userdata = JSON.stringify(dataJson);
    const uname = values.email;

    generateUser({variables: {username:uname , password:values.password, userdata:userdata}}).then((result) => {
    
      const {data : {generateUserM  : {success , token , id}}} = result

      console.log('set uid by signup ex:'+id);
      userDatas({...userDatas(), uid:'signup', token:token});
      notificationController.success({
        message: t('auth.signUpSuccessMessage'),
        description: t('auth.signUpSuccessDescription'),
      });
      navigate('/auth/login');
    }).catch(err => {
      //console.log('login error',createUserError)
      notificationController.error({ message: err.message });
      setLoading(false);
    })



    /*
    dispatch(doSignUp(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('auth.signUpSuccessMessage'),
          description: t('auth.signUpSuccessDescription'),
        });
        navigate('/auth/login');
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
    */
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{t('common.signUp')}</S.Title>
        <Auth.FormItem
          name="email"
          
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput onChange={(e:any)=>checkEmail(e)} placeholder={t('common.email')} />
        </Auth.FormItem>
        {existUser && <S.LoginDescription>{t('signup.existUser')}</S.LoginDescription>}
        {!existUser && <>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.confirmPassword')}
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true, message: t('common.requiredField') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
        </Auth.FormItem>

        <Auth.FormItem
          name="fullName"
          label={'name'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={'name'} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <Auth.Text>
                {t('signup.agree')}{' '}
                <Link to="/" target={'_blank'}>
                  <Auth.LinkText>{t('signup.termOfUse')}</Auth.LinkText>
                </Link>{' '}
                and{' '}
                <Link to="/" target={'_blank'}>
                  <Auth.LinkText>{t('signup.privacyOPolicy')}</Auth.LinkText>
                </Link>
              </Auth.Text>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper>
        <S.LoginDescription>{message}</S.LoginDescription>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.signUp')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('signup.alreadyHaveAccount')}{' '}
            <Link to="/auth/login">
              <Auth.LinkText>{'here'}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper></>}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
