import React, { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { userDatas } from '@app/hocs/cache';
import { makeReference, useReactiveVar } from '@apollo/client';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {

  const userData = useReactiveVar(userDatas);

//  useEffect(() => {
    const tokenKeeped = localStorage.getItem('userToken');
    if( tokenKeeped !== null && userData.token === '')
      userDatas({...userData, token: tokenKeeped});
//  },[]);

  return (tokenKeeped !== null || userData.token !== '')? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
