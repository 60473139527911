import React, { useEffect, useRef, useState } from 'react';
import { FilterIcon } from 'components/common/icons/FilterIcon';
import { SearchOverlay } from './searchOverlay/SearchOverlay/SearchOverlay';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { Btn, InputSearch } from '../YMHeaderSearch/HeaderSearch.styles';
import { useTranslation } from 'react-i18next';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { SearchOutlined } from '@ant-design/icons';

interface SearchOverlayProps {
  setSearchQuery: (keyword:string, query: string) => void;
  isOverlayOpen: boolean;
  setOverlayOpen: (state: boolean) => void;
}

export const SearchDropdown: React.FC<SearchOverlayProps> = ({
  setSearchQuery,
  isOverlayOpen,
  setOverlayOpen,
}) => {
  const [isFilterOpen, setFilterOpen] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [query, setQuery] = useState('' as string);
  const { t } = useTranslation();

  useEffect(() => {
    setOverlayOpen(!!keyword || isFilterOpen);
  }, [keyword, isFilterOpen, setOverlayOpen]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);
  
  const doSetFilter = () => {
    setSearchQuery(keyword,query);
    setKeyword('');
    setOverlayOpen(false);
    //setFilterOpen(false);
  }

  return (
    <>
      <BasePopover
        {...((isFilterOpen) && { trigger: 'click', onOpenChange: setOverlayOpen })}
        overlayClassName="search-overlay"
        content={<SearchOverlay setFilter={setQuery}  
        isFilterOpen={isFilterOpen} />}
        open={isOverlayOpen}
        getPopupContainer={() => ref.current}
      >
        <HeaderActionWrapper>
          <InputSearch
            width="100%"
            value={keyword}
            placeholder={t('header.search')}
            filter={
              <Btn
                size="small"
                type={isFilterOpen ? 'ghost' : 'text'}
                aria-label="Filter"
                icon={<SearchOutlined />}
                onClick={() =>doSetFilter()}
              />
            }
            onChange={(event) => setKeyword(event.target.value)}
            enterButton={null}
            addonAfter={null}
          />
          <div ref={ref} />
        </HeaderActionWrapper>
      </BasePopover>
    </>
  );
};
