import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { ReactComponent as FacebookIcon } from '@app/assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from '@app/assets/icons/google.svg';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { setUser } from '@app/store/slices/userSlice';
import { persistToken } from '@app/services/localStorage.service';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { createYMExecuterGQL, getYMExecuterGQL, signUpLoginM } from '@app/hocs/gql';
import { userDatas, ymDatas, ymaDatas } from '@app/hocs/cache';

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false); //check this  userDatas().uid !== ''); 
  const [keepLogin, setKeepLogin] = useState(false); //check this  userDatas().uid !== '');
  const [message, setMessage] = useState('');

  const [signUpLogin , signUpLoginRequest] = useMutation(signUpLoginM , {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
    if(data.signUpLoginM.success === true ) {
      //setMessage('');
        //setLogin( data.signUpLoginM.id === ''? 2:1);

        //localStorage.setItem('userToken' , data.signUpLoginM.token);
        //console.log('set uid by signin:'+data.signUpLoginM.id);
        //userDatas({...userDatas(), uid:'login'});

        //localStorage.setItem('exName' , email);
        //localStorage.setItem('exID' , data.signUpLoginM.id);
      }
      else{
        //setMessage(data.signUpLoginM.error);
      }
    }
  });
  
 
  const nextLogin = () => {
            //localStorage.setItem('userID',id); 
        //localStorage.setItem('exID',id); 
        setLoggedIn(true);  
        setLoading(true);
        navigate('/accessallow')    
        //navigate('/member/examinee-dashboard')    
        //props.completed(id);
  }
    
  const handleSubmit = (values: LoginFormData) => {

    signUpLogin({variables: {username:values.email , password:values.password}}).then((result) => {
      const {data : {signUpLoginM  : {success , token , id}}} = result
      //console.log('user token ',token)
      if( success === true ){
        console.log('set uid by signin ex:'+id);
        userDatas({...userDatas(), uid:'login', token:token});
        if( keepLogin) localStorage.setItem('userToken',token);
        nextLogin();
        /*
        getExecuterHandler({variables: {uid: id},notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only'
          // pollInterval: 500
        });
*/
      }
      else{
        setMessage( "패스워드가 잘못 되었습니다.");
          //result.data.signUpLoginM.error);
      }
    }).catch(err => {
      console.log('login error',signUpLoginRequest.error)
      console.log(err)
     })

    /*
    dispatch(setUser('res.user'));
    persistToken('res.token');
    navigate('/member/examinee-dashboard')
    dispatch(doLogin(values))
      .unwrap()
      .then(() => navigate('/member/examinee-dashboard'))
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });*/
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>{t('common.welcome')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox checked={keepLogin} onChange={()=>{setKeepLogin(!keepLogin)}}>
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          {/*
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link> */}
        </Auth.ActionsWrapper>
        <S.LoginDescription>{message}</S.LoginDescription>

        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('login.noAccount')}{' '}
            <Link to="/auth/sign-up">
              <Auth.LinkText>{t('common.here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
