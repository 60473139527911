import React, { useState } from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { BaseFormTitle } from '@app/components/common/forms/components/BaseFormTitle/BaseFormTitle';
import { CheckOutlined, PlusOutlined, ScheduleOutlined, SearchOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ValidationForm } from '@app/components/forms/ValidationForm/ValidationForm';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { HeaderActionWrapper } from '../Header.styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import styled from 'styled-components';
import { RegistrationForm } from '@app/components/forms/RegistrationForm/RegistrationForm';


interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {

  return (
    <BaseRow align="middle" justify="end" style={{width:'100%'}}>
      <S.ProfileColumn xl={5} xxl={5} $isTwoColumnsLayout={isTwoColumnsLayout} >
        <BaseRow align="middle" justify="start" gutter={[15, 10]} style={{position:'fixed',zIndex:99, backgroundColor:'#fffc',borderRadius: '15px'}} >
          <BaseCol>
            <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>
              <BaseCol>
                <HeaderFullscreen />
              </BaseCol>

              <BaseCol>
                <NotificationsDropdown />
              </BaseCol>

              <BaseCol>
                <SettingsDropdown />
              </BaseCol>
            </BaseRow>
          </BaseCol>
          <BaseCol>
            <ProfileDropdown />
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};
