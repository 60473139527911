import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';

import { ScheduleCard } from '@app/components/examinee-dashboard/scheduleCard/ScheduleCard';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { getAllYMExecutersGQL } from '@app/hocs/gql';
import { Dates } from '@app/constants/Dates';
import { getExamMonth } from '@app/constants/YMData';
import { ymDatas, ymaDatas } from '@app/hocs/cache';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const ScheduleForm = (props:any) => {
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  
  const ymData = useReactiveVar(ymDatas);

  const [dateFormatted, setDateFormated] = useState('');
  const bmonth = getExamMonth(ymData.tg.data.bdate,ymData.tg.data.epweek,ymData.tg.data.epday);

  useEffect(()=>{
    console.log('******************* ScheduleForm useEffect:'+ymData.update+':'+ymData.modal);
  },[ymData.update]);

  useEffect(()=>{
    setDateFormated(Dates.format(parseInt(ymData.tg.reservedAt), 'YYYY년 M월 D일 - H시 m분'));
  },[ymData.tg.reservedAt]);

  const onFinish = async (values:any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setFieldsChanged(true);
      console.log(values);
      //props.onOk();
    }, 100);
  };

  
  return (
    <BaseButtonsForm
      {...formItemLayout}
      layout="inline"
      
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      initialValues={{
        'input-number': 3,
        'checkbox-group': ['A', 'B'],
        rate: 3.5,
      }}
      footer={
        <BaseButtonsForm.Item style={{display: 'flex', justifyContent: 'flex-end', width:'100%'}}>
          <BaseSpace align='end'>
          <BaseButton type="primary" htmlType="submit" loading={isLoading} onClick={props.onCancel}>
            취소
          </BaseButton>
          <BaseButton type="primary" htmlType="submit" loading={isLoading} onClick={props.onOk}>
            등록하기
          </BaseButton>
          </BaseSpace>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >

    <BaseRow gutter={[10,10]}>
      
      <BaseCol span={12}>
      <BaseButton round type="default" >
      {ymData.tg.data.name}  |  { bmonth/12|0} 년 { bmonth%12} 개월  |  {ymData.tg.data.uid}
      </BaseButton>
      </BaseCol>

      <BaseCol span={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
        <BaseButton round type="ghost" size="small"> 
          등록 : {ymData.eqid !== ''? ymaDatas().eqs.find((v:any)=>v.id === ymData.eqid).name:'미정'}
        </BaseButton>
       </BaseCol>

      <BaseCol span={8} style={{display: 'flex', justifyContent: 'flex-end'}}>
      <BaseButton round type="default" >
      {dateFormatted}
      </BaseButton>
      </BaseCol>

      <BaseCol span={24}>
        <ScheduleCard />
      </BaseCol>

    </BaseRow>
    </BaseButtonsForm>
  );
};
