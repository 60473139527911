import { useTranslation } from 'react-i18next';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import "./video.css"
import { Dates } from '@app/constants/Dates';
import { useMutation, useReactiveVar } from '@apollo/client';
import { ymDatas } from '@app/hocs/cache';
import { getExamMonth, getMonth, isLocal } from '@app/constants/YMData';
import { Progress, Typography } from 'antd';
import { Upload, UploadOptions } from 'tus-js-client';
import { createYMTaskGQL, updateYMTaskGQL } from '@app/hocs/gql';
import create from '@ant-design/icons/lib/components/IconFont';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const VideoForm = (props:any) => {
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const cWidth = 711; 
  const [offsetX,setOffsetX] = useState(0);
  const [offsetY,setOffsetY] = useState(0);

  const [vRatio,setVRatio] = useState(16/9); // r = w/h = 16/9
  const [vWidth, setVWidth] = useState(0);
  const [vHeight, setVHeight] = useState(0);
  const previewRef = useRef<HTMLVideoElement>(null); // preview video
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [comment,setComment] = useState("");
  const [vURL,setVURL] = useState("");

  const ymData = useReactiveVar(ymDatas);
  const [dateFormatted, setDateFormated] = useState('');
  const bmonth = getExamMonth(ymData.tg.data.bdate,ymData.tg.data.epweek,ymData.tg.data.epday);

  const vData = useRef<any[]>([]);

  const videourl = useRef('');
  const thumburl = useRef('');
  const tkData = useRef<any>({});
  const typedComment = useRef('');
  const [videoWidth,setVideoWidth] = useState(cWidth);
  const [process,setProcess] = useState(1); // 0:video, 1:no video, 2:uploading, 3: uploaded, 4:video size error
  const [progress,setProgress] = useState(0);
  const [vIdx,setVIdx] = useState(0);

  useEffect(()=>{
  },[])

  const updateTime = useRef(0);
  useEffect(()=>{
    if( ymData.modal === 3 && ymData.update !== updateTime.current){
      setProcess(1);
      setVIdx(0);
      updateTime.current = ymData.update;
      vData.current = [];
      //console.log('******************* videoForm useEffect:'+ymData.update+':'+ymData.modal);
      
      if(ymData.tk.length === 0){
        createTask(ymData.tg.data.gid,ymData.tg.data.id);
      } 
      else{
        setDateFormated(Dates.format(parseInt(ymData.tg.reservedAt), 'YYYY년 M월 D일 - H시 m분'));
        const idx = ymData.tk.map((task:any,idx:any) => {
          vData.current[idx] = JSON.parse(ymData.tk[idx].data);
          vData.current[idx].title = ymData.tk[idx].title;
          if( vData.current[idx].video !== '' )
            return idx;
        });
        if( idx[0] !== undefined){
          selectTask(idx[0]);
        }   
      }
    }
  },[ymData.update]);

  const onFinish = async (values = {}) => {
    videourl.current = '';
    setComment('');
    setVRatio(0);
    setVWidth(0);

    setTimeout(() => {
      setFieldsChanged(true);
      //notificationController.success({ message: t('common.success') });
      //console.log(values);
      props.onOk();
    }, 100);
  };

  const selectTask = (idx:number) => {
    setVIdx(idx);
    if( vData.current[idx] === undefined ) return;
    //console.log( 'selectTask('+process+'):'+idx);
    videourl.current = vData.current[idx].video;
    setProcess(process === 0 ? 3:0);
    setComment(vData.current[idx].comment);
    setVideoArea( vData.current[idx].vr);
  }

  const refreshVideo = (idx:number) => {
    if( vData.current[idx] === undefined ) return;

    videourl.current = vData.current[idx].video;
    setProcess(process === 3 ? 0:3);
    setComment(vData.current[idx].comment);
    setVideoArea( vData.current[idx].vr);
  }

  const setVideoArea = (vr:number) => { 
    //console.log('setVideoArea('+process+') - vr:'+vr)
    setVRatio(vr);
    if( vr === 0 ) vr = 1.4;
    if( vr > 1 ){   // horiz
      let vW = 711;
      if( vr < 1.4 ) vW = 711*0.7;
      const vH = vW/vr;

      setVWidth(vW-1); //400/vRatio|0));
      setVHeight(vH>400?400:vH);  
      setOffsetX((cWidth - vW)<0?0:(cWidth - vW)/2);
      setOffsetY((400 - vH)<0?0:(400 - vH)/2);
    }
    else if( vr === 0 ){
      // default 1.4
      setVHeight(0);  
      setVWidth(0);
      setOffsetX(0);
      setOffsetY(0);
    }
    else{ // vert
      const vW = (400*vr|0);
      setVHeight(400);  
      setVWidth(vW);
      setOffsetX((cWidth - vW)<0?0:(cWidth - vW)/2);
      setOffsetY(0);
    }  
  }

  const [uploadVideo, setUploadVideo] = useState(false);
  const fileInput = useRef<any>(null);
  const videoFile = useRef<File|null>(null);
  const [fileSize, setFileSize] = useState(0);

  const selectFile = async (e:React.FormEvent<HTMLInputElement>) => {
    //previewRef.current.src
    if( fileInput.current.files.length !== 0 )
      setUploadVideo(true);
    else if( fileInput.current.files[0] === undefined ) return;
    
    const size = ((fileInput.current.files[0].size as number)/(1024*1024)|0);
    setFileSize(size);
    if( previewRef.current !== null && size < 100){
      const video = document.getElementById('video-preview');

      if( video !== null ) {
        video.onloadedmetadata = function (e:any) {
        setVideoArea( e.target.videoWidth/e.target.videoHeight);
        };
      }

      if( fileInput.current.files.length !== 0 ){

        const reader = new FileReader();
        videoFile.current = fileInput.current.files[0];
        reader.readAsDataURL( videoFile.current as Blob);
        reader.addEventListener('load', function(){
          if( video !== null ){
            video.setAttribute('src', reader.result as string);
            //video.src = reader.result; // bloburl
            console.log(video);
          }
          else
            ymData.tk[vIdx].data.video !== '' ? setProcess(0):setProcess(1);
        });  
      }
    }
    else 
      setProcess(4);
  }

  const fileUpload = async (e:Event) => {
    e.preventDefault();
    let file = null;
    console.log('[',fileInput.current.files.length,']');
    if( fileInput.current.files.length !== 0 ){
      file = fileInput.current.files[0];
      startUpload( file, 5 * 1024 * 1024);
    }
  }

  const getExpiryDate = () => {
    const theDate = new Date();
    theDate.setHours(theDate.getHours() + 5);
    return theDate.toISOString();
  };

  const [createYMTask, createYMTaskRequest] = useMutation(createYMTaskGQL , {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
    if(data.createYMTask.success === true ) {
      //setMessage('');
    }
    else{
      notificationController.error({ message: data.createYMTask.error});
    }
    }
  });

  const createTask = (gid:any,tgid:any) => {
      const tg = ymData.tg;
      const exMonth = getExamMonth( tg.data.bdate, tg.data.epweek, tg.data.epday);
      const bmonth = getMonth(tg.data.bdate);

      createYMTask({variables: { 
        "input": {
          "owner": ymData.eqid,
          "title": "",
          "category": "e_0",

          "data": JSON.stringify({
            "name":"",
            "video":"",
            "thumb":"",
            "comment":"",
            "vr":0,
            "vwidth":0,
            "bmonth": bmonth,
            "exmonth": exMonth,
          }),

          "executeAt": new Date(),

          "group": tg.group.id,
          "target": tg.id,

        }
      }})
      .then((result) => {
        const {data : {createYMTask  : {success , data , id}}} = result;
        ymData.tk.push({id: id, data:data, status:0, title:''});
        ymDatas({...ymData, tk:ymData.tk, tkid: id});
        vData.current.push({id: id, data:JSON.parse(data), status:0, title:'video'+(vData.current.length+1)});
        
        if( vData.current.length === 1)
          createTask(gid,tgid);

        selectTask(0);
      }).catch(err => {
        //console.log('login error',createUserError)
        //d console.log(err)
      })

  }

  const [updateYMTask, updateYMTaskRequest] = useMutation(updateYMTaskGQL , {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
    if(data.updateYMTask.success !== true ) {
      notificationController.error({ message: data.updateYMTask.error });
    }
    }
  });
  
  const updateTask = () => {
    tkData.current.video = videourl.current;
    tkData.current.thumb = thumburl.current;
    tkData.current.comment = typedComment.current;
    tkData.current.vr = vRatio;
    tkData.current.vwidth = videoWidth;

    updateYMTask({variables: { 
      id: ymData.tk[vIdx].id,
      input: {
        data: JSON.stringify(tkData.current),
        status:2,
        executedAt: new Date(),
      }
    }})
    .then((result) => {
      const {data : {updateYMTask  : {success , data , id}}} = result;
      console.log('updateYMTask - data:'+data)

      vData.current[vIdx].video = videourl.current;
      vData.current[vIdx].comment = typedComment.current;
      vData.current[vIdx].vr = vRatio;
      uploadWorkingModify.current = 0;
    }).catch(err => {
      console.log(err)
    })

  }

  const uploadWorkingModify = useRef(0);

  const startUpload = (file:any, chunkSize:number) => {
    const uploadName = 'admin_'+ymData.tg.data.name+'_'+vIdx+'.mp4';
    if( uploadWorkingModify.current  === file.lastModified ) return;
    uploadWorkingModify.current = file.lastModified;
    const tusUploadOptions = {
      endpoint: "https://cloudflare-streams-tus-upload.blueton.workers.dev/",
      chunkSize: chunkSize,
      metadata: {
        expiry: getExpiryDate(),
        //maxDurationSeconds: 3600,
        name: uploadName, //file.name,
        //"Upload-Metadata": "requiresignedurls,maxdurationseconds MzYwMA==",
      },
      onError(error:Error) {},
      onSuccess() {
        // https://customer-9e9lqt027etamtgx.cloudflarestream.com/90e44de81c5746f57098abe31a979d3e/watch
        updateTask();
        setUploadVideo(false);
        setProcess(3);
        setProgress(0);
        setBRefresh(true);
      },
      onAfterResponse(req:any, res:any) {
        
        return new Promise(resolve => {
          const mediaIdHeader = res.getHeader('stream-media-id');
          if (mediaIdHeader) {
            console.log("mediaId:"+mediaIdHeader);    
            const url = req.getURL()
            const vid = mediaIdHeader;
            videourl.current = "https://customer-9e9lqt027etamtgx.cloudflarestream.com/"+vid+"/watch";
            thumburl.current = "https://customer-9e9lqt027etamtgx.cloudflarestream.com/"+vid+"/thumbnails/thumbnail.jpg";
    
          }
          resolve();
        });
        
      },
      onProgress(bytesUploaded:number, bytesTotal: number) {
        //const progress = (bytesUploaded / file.size) * 100;
        const percentage = ((bytesUploaded / bytesTotal) * 100)|0; //.toFixed(2); Math.floor(progress)
        setProgress(percentage);
      },
    } as UploadOptions;
  
    const tusUpload = new Upload(file, tusUploadOptions);
    tusUpload.start();
  }

  const onLoad = (e:any) => {
    //console.log('onLoad::'+e.toString());
    loadScript();
  };

  const videoScript = useRef<any>(null);


  const loadScript = () => {
    videoScript.current = document.createElement('script');

    videoScript.current.src = "https://embed.cloudflarestream.com/embed/sdk.latest.js";
    //script.defer = true;
    videoScript.current.type = "text/javascript"
    videoScript.current.async = false;

    videoScript.current.onload = ()=>{
      //console.log('onload');
      //selectTask(0);
    // @ts-ignore
      const player = Stream(document.getElementById('stream-player'));

      player.addEventListener('play', (e:any) => {
        console.log('playing!' + e.toString());
      });
      player.addEventListener('canplay', (e:any) => {
        //console.log('can play!' + e.toString());
        setProcess(0);
        setBRefresh(false);
        try{ //if (document.body.contains(videoScript.current))
          document.body.removeChild(videoScript.current);
        } catch {}
      });
      player.addEventListener('loadedmetadata', (e:any) => {
        if(e.target === null) return;
        //console.log('loadedmetadata!' + e.target.videoWidth +'/'+ e.target.videoHeight);
      });
      player.play().catch(() => {
        //console.log('playback failed, muting to try again');
        player.muted = true;
        //player.play();
      });
      player.addEventListener('error', (e:any) => {
        //console.log('error!' + e.toString());
        selectTask(vIdx);
      });
      //player.play();
    };
    document.body.appendChild(videoScript.current);
  }

  const [bRefresh,setBRefresh] = useState(false);

  const getIframeStream = () => {
    //videourl.current = "https://customer-9e9lqt027etamtgx.cloudflarestream.com/a402958984286f71851d2e8a0e508c17/iframe";
    if( videourl.current === '' || videourl.current === undefined ) return;

    const vurl = videourl.current.replace('watch','iframe')+'?update='+Date.now()+'&preload=auto';//+'&autoplay=true';  
    //console.log('getIframeStream : ('+offsetX+','+offsetY+','+vWidth+','+vHeight+')url:'+vurl);

    return <iframe src={vurl} id='stream-player' ref={iframeRef} onLoad={(e)=>onLoad(e)} 
    style={{border: 'none', position: 'absolute', zIndex:100, maxHeight: '400px', maxWidth: '711px',
    top: offsetY, left: offsetX, height: vHeight === 0 ? '80%':vHeight+'px', width: vWidth === 0 ? '80%':vWidth+'px', 
    borderRadius: '10px', overflow:"visible"}}  
    allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;" allowFullScreen={true}></iframe>
    //  autoplay;
  }   

  const getPreviewVideo = () => {
    //console.log('getPreviewVideo : ('+offsetX+','+offsetY+','+vWidth+','+vHeight+')');
    return <video ref={previewRef} id="video-preview"
    preload="metadata"
    style={{ position: 'absolute', overflow:'clip',
    top: offsetY, left: offsetX, maxHeight: '400px', maxWidth: '711px',
    //left: "0px", //width: (cWidth)+"px", height: (400)+"px", 
    borderRadius: '10px', objectFit: "cover", zIndex:20}}
    width={vWidth === 0 ? '80%':vWidth+'px'}
    height={vHeight === 0 ? '80%':vHeight+'px'}
    max-height={'400px'}
    autoPlay={false} controls={true}
    playsInline
    onLoadedMetadata= {(e:BaseSyntheticEvent) => {
      // cWidth 
      const vw = e.target.videoWidth, vh =e.target.videoHeight; 
      const vr = vw/vh; // 1.4 
      console.log(vw+','+vh+','+vr);
      setVideoArea(vr);
    }}></video>
  }

  const getVideoMessage = ( message:any) => {
      return  <div style={{ position: 'absolute', top: '0%', left:0, height: '400px', width: '711px', backgroundColor:'#fffa', backgroundPosition: 'center', 
      backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderRadius: '10px', border:process===3?'0px solid #6E82FE':'1px solid #6E82FE', 
      display:'flex', flexDirection: 'column',alignItems: 'center',justifyContent:'center', zIndex:111}}>
        <Typography style={{padding:5, marginTop:1, marginLeft:1, color:'#495057', fontSize: 12, fontWeight: 400, whiteSpace: 'pre-line'}}>{message}</Typography></div>
  }

  return (
    <BaseButtonsForm {...formItemLayout} layout="inline" isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      initialValues={{'input-number': 3,'checkbox-group': ['A', 'B'],rate: 3.5,}}
      footer={
        <BaseButtonsForm.Item style={{display: 'flex', justifyContent: 'flex-end', width:'100%'}}>
          <BaseSpace align='end'><BaseButton type="primary" htmlType="submit" >닫기</BaseButton></BaseSpace>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}>
    <BaseRow gutter={[10,10]}>
      <BaseCol span={12}>
      <BaseButton round type="default" onClick={()=>{return;}}>{dateFormatted}</BaseButton>
      </BaseCol>

      <BaseCol span={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
      <BaseButton round type="text" onClick={()=>{return;}}>
      {ymData.tg.data.name}  |  { bmonth/12|0} 년 { bmonth%12} 개월  |  {ymData.tg.data.uid} | {vIdx}
      </BaseButton>
      </BaseCol>
      {videourl.current.split('/')[3]}

      <BaseCol span={18}>
      <div style={{ height: "400px", width: "711px", backgroundColor:'#ffffff'}}>
        { (process === 0 || process ===3) && getIframeStream()}
        { process === 1 && getVideoMessage("해당 과제의 영상이 없습니다. 영상을 업로드 해주세요.")}
        { process === 2 && getPreviewVideo()}
        { process === 3 && getVideoMessage(bRefresh?"해당 과제의 영상을 서버에서 엔코딩 중입니다. Refresh버튼을 눌러서 확인해주세요.":"해당 과제의 영상을 서버에서 불러오는 중입니다.")}
        { process === 4 && getVideoMessage("해당 과제의 영상이 너무 큽니다. 바로 업로드 해주세요. 미리보기는 영상 업로드 완료후 확인 가능합니다.("+fileSize+"MB)")}
        { bRefresh && <BaseButton round  type="default" onClick={()=>{refreshVideo(0)}} 
          style={{top: '20px', left:'20px',marginBottom:'15px', width: '120px', backgroundColor: '#556AEB', color: 'white', zIndex:120}}>Refresh</BaseButton>}
      </div>
      </BaseCol>

      <BaseCol span={6}  >
        <BaseSpace wrap={true} align='center' style={{display: 'flex', flexDirection:'column', justifyContent: 'space-between', height:'400px'}}>
          <BaseCol span={24} >
            { vData.current.map((task:any,idx:any) => {
              return <BaseButton round key={idx} type="default" onClick={()=>{selectTask(idx)}} style={{marginBottom:'15px', width: '120px', backgroundColor: '#556AEB', color: 'white'}}>{task.title===''?'영상':task.title}</BaseButton>
            })}
          </BaseCol>
          {(process === 2 || process === 4) && uploadVideo && 
            <BaseCol span={24} style={{ width: 170 }}><Progress percent={progress} size="small" width={100}/></BaseCol>}
          <BaseCol span={24} >
            <BaseSpace wrap={false} align='center'>
              <BaseButton onClick={()=>{fileInput.current.click(); setVideoArea(0); setProcess(0); setProcess(2)}}>파일 찾기</BaseButton>
              <input ref={fileInput} id="file" type="file" accept="video/*" style={{ display: 'none' }} onChange={(e:any)=>selectFile(e)}/>
              {uploadVideo&& <BaseButton onClick={(e:any)=>fileUpload(e)}>업로드</BaseButton>}
            </BaseSpace>
          </BaseCol>
        </BaseSpace>
      </BaseCol>
      <BaseCol span={24}>{comment}</BaseCol>
    </BaseRow>
    </BaseButtonsForm>
  );
};

/*
{message}
        {vRatio +'/'+ vWidth +'/'+ vHeight +'/'+ (400/vRatio|0) +'/'+ (400*16/9|0)}
      <BaseButton type="default" onClick={()=>{return;}} style={{marginBottom:'15px', width: '120px', backgroundColor: '#EBEFFF'}}>블록 쌓기</BaseButton>
        <Stream controls responsive={true} src={videourl.current} height="500px"/>
      <BaseCol span={24} >
      <ReactPlayer className='react-player' width={'99%'} height='100%' controls={true} playing 
        url={videourl.current} 
        light={<img src={thumburl.current} alt='Thumbnail' />} 
        />
      </BaseCol>

          <div style={{ position: 'absolute', top: '0%', height: '400px', width: '711px', backgroundColor:'white', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderRadius: '10px', border:'1px solid #6E82FE', display:'flex', flexDirection: 'column',alignItems: 'center',justifyContent:'center', zIndex:10}}>
            <Typography style={{padding:5, marginTop:1, marginLeft:1, color:'#495057', fontSize: 12, fontWeight: 400, whiteSpace: 'pre-line'}}>file size: {fileSize}MB</Typography></div>}

            <div style={{ position: 'absolute', zIndex:101, top: '0%', height: '400px', width: '711px', backgroundColor:'#fffa', backgroundPosition: 'center', 
      backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderRadius: '10px', border:'1px solid #6E82FE', 
      display:'flex', flexDirection: 'column',alignItems: 'center',justifyContent:'center'}}>
        <Typography style={{padding:5, marginTop:1, marginLeft:1, color:'#495057', fontSize: 12, fontWeight: 400, whiteSpace: 'pre-line'}}>해당 과제의 영상이 업로드중입니다.</Typography>
        </div>}

          onError={(e:SyntheticEvent) => { setMessage(e.toString()); }}
          onCanPlay={(e:SyntheticEvent) => { setMessage(e.target.toString());}} 
      <video ref={previewRef} id="video-preview"
                preload="metadata"
                style={{ position: 'absolute', top: '0%', 
                //left: "0px", //width: (cWidth)+"px", height: (400)+"px", 
                borderRadius: '10px', objectFit: "cover", zIndex:20}}
                width={vWidth}
                autoPlay={false} controls={true}
                playsInline
                onLoadedMetadata= {(e:SyntheticEvent) => {
                  // cWidth 
                  // @ts-ignore | Weird 
                  const vw = e.target.videoWidth, vh =e. target.videoHeight; 
                  const vr = vw/vh; // 1.4 
                  setVRatio(vr);

                  if( vr > 1 ){ // horiz r = w/h
                    setVWidth(cWidth-30);
                    setVHeight((cWidth-30)/vr);      
                  }
                  else{ // vert
                    // vr 0.75 : 1.6
                    // vr 0.56 : 1.9
                    let wCoeff = 0.55;
                    if( vr < 0.65 )
                      wCoeff = 0.45;
          
                    setVWidth((cWidth-30)*wCoeff);
                    setVHeight((cWidth-30)*wCoeff/vr);      
                  }

                  setVideoWidth(vw);
                  console.log(vw+','+vh+','+vr);
        
                }}
                ></video>

      <div style={{ height: "450px", width: "200px"}}>
      <Stream controls src={video} className="videoStream"/>
      </div>
  // https://developers.cloudflare.com/stream/uploading-videos/direct-creator-uploads/
  // https://capacitorjs.com/docs/web/pwa-elements
  // https://github.com/JesusFilm/core

  const startUploadDirect = (file:any, size:number) => {

      const tusUploadOptions = {
        endpoint: "https://api.cloudflare.com/client/v4/accounts/3ede8243614dc67aeb7b4473d31a2825/stream?direct_user=true",
        headers: {
          Authorization: 'Bearer FE3ZmuZrzDDYA2czAm9YRxfZZ90kJMxcbzn82C08',
        },
        chunkSize: 50 * 1024 * 1024,
        retryDelays: [0, 3000, 5000, 10000, 20000], // Indicates to tus-js-client the delays after which it will retry if the upload fails
        metadata: {
          filename: file.name,
          filetype: "video/mp4",
          expiry: getExpiryDate(),
          //maxDurationSeconds: 3600,
          name: 'video.mp4', //file.name,
          //"Upload-Metadata": "requiresignedurls,maxdurationseconds MzYwMA==",
        },
        uploadSize: size,
  
      onError(error:Error) {},
      onSuccess() {
        // https://customer-9e9lqt027etamtgx.cloudflarestream.com/90e44de81c5746f57098abe31a979d3e/watch
        updateTask();
        setUploadVideo(false);
      },
      onAfterResponse(req:any, res:any) {
        const url = req.getURL()
        const vid = res.getHeader('stream-media-id');
        videourl.current = "https://customer-9e9lqt027etamtgx.cloudflarestream.com/"+vid+"/watch";
        thumburl.current = "https://customer-9e9lqt027etamtgx.cloudflarestream.com/"+vid+"/thumbnails/thumbnail.jpg";

      },
      onProgress(bytesUploaded:number, bytesTotal: number) {
        //const progress = (bytesUploaded / file.size) * 100;
        const percentage = ((bytesUploaded / bytesTotal) * 100)|0; //.toFixed(2); Math.floor(progress)
        setProgress(percentage);
      },
    } as UploadOptions;
    
    const upload = new Upload(file, tusUploadOptions);
    upload.start();
  }

      */
         /*
    window.addEventListener( "loadedmetadata", (e:any) => {
      setVideoAttribute(e.target.videoWidth, e.target.videoHeight);
    }, true);
    */
   /*
    const script = document.createElement('script');

    script.src = "https://embed.cloudflarestream.com/embed/sdk.latest.js";
    script.async = true;

    document.body.appendChild(script);
*/

    /*
    if( vw === 0 ) vw = 711;
    if( vh === 0 ) vh = 400;
    //const vw = previewRef.current !== null ? previewRef.current.videoWidth:0, vh =previewRef.current !== null ? previewRef.current.videoHeight:0; 
    setVRatio(vr);

    if( vr > 1 ){ // horiz r = w/h
      setVWidth(cWidth-30);
      setVHeight((cWidth-30)/vr);      
    }
    else{ // vert
      // vr 0.75 : 1.6
      // vr 0.56 : 1.9
      let wCoeff = 0.55;
      if( vr < 0.65 )
        wCoeff = 0.45;

      setVWidth((cWidth-30)*wCoeff);
      setVHeight((cWidth-30)*wCoeff/vr);      
    }

    //setVideoWidth(vw);
    setMessage(vw+','+vh+','+vr);
    */
    /*
    if( vr < 100 ) setVideoWidth(400);
    else if( vr < 140 ) setVideoWidth(170);
    else if( vr < 180) setVideoWidth(140);
    else setVideoWidth(100);
    */

       /*
   const playerRef = useRef(null);
   const handlePlayerReady = (player:any) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

   // https://videojs.com/guides/react/
   const getVideoJS = () => {
    const videoJsOptions = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      loop: true,
      sources: [{
        src: 'https://customer-m033z5x00ks6nunl.cloudflarestream.com/b236bde30eb07b9d01318940e5fc3eda/manifest/video.m3u8',//'https://customer-9e9lqt027etamtgx.cloudflarestream.com/a402958984286f71851d2e8a0e508c17/watch',
        //type: 'video/w'//'application/x-mpegURL' //'video/mp4'
      }]
    };

    return (
      <>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </>
    );
   }
// style={{ position: 'absolute', top: '0%', width: '711px', height: '400px', borderRadius: '10px', overflow:'clip', div:{ div: { width: '100%', height: '100%', overflow:"visible"}}, aspectRatio: `${vRatio}`, marginTop:1} as React.CSSProperties}
  const videoRef = useRef();

   const getStream = () => { // React.CSSProperties // https://github.com/ant-design/ant-design/blob/master/components/tag/CheckableTag.tsx#L33
    return null;
   }
*/
       /*
    return <div className='player'><Stream src={'https://customer-9e9lqt027etamtgx.cloudflarestream.com/a402958984286f71851d2e8a0e508c17/watch'} 
    loop={true}
    controls={false}
    streamRef={videoRef}
    onLoadedMetaData={(e:any)=>{console.log("onLoadedMetaData:"+e)}}
    // @ts-ignore
    onProgress={() => console.log(videoRef.current?.currentTime)}
    autoplay={true}
    muted={true} /></div>
    */
   /*
  useEffect(()=>{
    selectTask(selectedIdx);
  } ,[selectedIdx]);

  useEffect(()=>{
    if( process === 0){
      const stream = document.getElementById('stream-player');
      if( stream !== null){
        const player = Stream(stream as HTMLIFrameElement) as any;
        if( player !== null ){
          player.addEventListener('play', () => {
            console.log('playing!');
          });
          player.play().catch(() => {
            console.log('playback failed, muting to try again');
            player.muted = true;
            player.play();
          });    
        }
      }
    }
  },[process]);  
*/
    /*
    if( iframeRef.current !== null ){
      //@ts-ignore
      const player = window.Stream(iframeRef.current) as any;
      if(player !== null){
        player.addEventListener('play', () => {
          console.log('playing!');
        });
        player.addEventListener('error', () => {
          console.log('error!');
        });
  
      }

    }
    */

/*
    const vp = document.getElementById('stream-player');
    if( vp !== null ){
    // @ts-ignore
    vp.src = videourl.current;
    // @ts-ignore
    const player = Stream(vp);
    //player.play();
    vp.onplay = function() {
      console.log('playing!');
    }
    vp.onloadedmetadata = function(e:any) {
      console.log(e);
    }
      player.addEventListener('play', () => {
        console.log('playing!');
      });
      player.play().catch(() => {
        console.log('playback failed, muting to try again');
        player.muted = true;
        player.play();
      });  
    }
*/
/*      
      file = fileInput.current.files[0];
      const f = fs.createReadStream(file.name);
      const size = fs.statSync(file.name).size;
      if( file !== null ) startUpload( f, size);

      const f = new File(
        //[blob], `video_${Date.now()}.webm`, {type: 'video/webm'}
        fileInput.current.files[0], {type: 'video/webm'}
        );
*/        
/*
      const video = document.getElementById('video-preview');
      if( video !== null ){
        const srcUrl = video.getAttribute('src');
        if( srcUrl !== null ){
          const blob = await fetch(srcUrl).then(r => r.blob());
          if( file !== null ) startUpload( blob, 5 * 1024 * 1024,);
      //        if( file !== null ) startUpload( file, 5 * 1024 * 1024,);
        }    
        
      }
      */
        /*
        const si = setInterval(async() => {
          
          const result = await fetch(
            'https://api.cloudflare.com/client/v4/accounts/3ede8243614dc67aeb7b4473d31a2825/stream/'+videourl.current.split('/')[3],
            {
              headers: {
                'Authorization': 'Bearer FE3ZmuZrzDDYA2czAm9YRxfZZ90kJMxcbzn82C08',
                'Tus-Resumable': '1.0.0',
              },
              method: 'GET'
            },
          )
          //@ts-ignore
          console.log(result.readyToStream);
          
        }, 2000);
        */
