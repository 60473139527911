import { gql} from "@apollo/client";

// --------------------------------------------------- 

export const generateHistoryGQL = gql`
mutation generateHistory( $id: ID! $input:CardUpdate!){
  generateHistory( id:$id, input: $input) {
    success
    error
    id
    data
  }
}
`;
/*
export const getFlowGQL = gql`
query{
  getFlow(
    id : "62bc2a2bbec7bf7236122c6e"
  ){
    id
    flow{
      cards{
        id
        title
        category
        data
      }
    }
    success
    error
  }
}
`;
*/
export const getFlowGQL = gql`
query getFlow($id: ID!){ getFlow(id:$id){
    id
    flow{
    cards{
        id
        title
        subtitle
        category
        data
        linkto
        img
    }
    }
    success
    error
}
}
`;


//write Query for Reactvar
//this query is independent of server graphql


//---------------------------------------------
export const userGQL = gql`
  query UserQuery {
    userData {
      id
      age
      details
      access_date_utc
    }
  }
`;


export const getUserByName = gql`
query getUserByName( $username: String!){
  getUserByName( username:$username){
    id
    user{
			id
      username
      flowid
    }
    success
    error
  }
}
`;

export const getUserByToken = gql`
query getUserByToken( $token: String!){
  getUserByToken( token:$token){
    id
    user{
			id
      username
      flowid
      cards{
        category
        title
        subtitle
        data
        linkto
        color
        img
        id
        editable
        source
        shape
      }
      flows{
        id
        category
        title
        editable
        source
      }
    }
    success
    error
  }
}
`;

export const logIn = gql`
mutation logIn( $username:String! $password: String!){
  logIn( username: $username, password: $password) {
    success
    token
    error
    id
  }
}
`;

export const signUpLoginM = gql`
mutation signUpLoginM( $username:String! $password: String!){
  signUpLoginM( username: $username, password: $password) {
    success
    token
    error
    id
  }
}
`;

export const generateUserM = gql`
mutation generateUserM( $username:String! $password: String! $userdata: String!){
  generateUserM( username: $username, password: $password, userdata: $userdata) {
    success
    token
    error
    id
  }
}
`;


export const getYMGroupListGQL = gql`
query getYMGroupList($code: String!) {
  getYMGroupList(code: $code) {
    data
    error
    id
    idx
    success
  }
}
`;

export const updateYMGroupListGQL = gql`
mutation updateYMGroupList($code: String!, $input: YMGroupListInput!) {
  updateYMGroupList(code: $code, input: $input) {
    data
    error
    id
    success
  }
}
`;


export const getYMCountGQL = gql`
query getYMCount($code: String!){ 
  getYMCount(code:$code){
    code
    count
    success
    error
}
}
`;


export const getAllYMExecutersGQL = gql`
query getAllYMExecuters {
  allYMExecuters {
    id
    authorized
    name
    data
  }
}
`;


export const getYMExecuterGQL = gql`
query getYMExecuter($uid: ID!){ getYMExecuter(uid:$uid){
    id
    executer{
      id
      authorized
      category
      name
      data 
      status
    }
    success
    error
}
}
`;

export const createYMExecuterGQL = gql`
mutation createYMExecuter( $input:YMExecuterInput!){
  createYMExecuter(input: $input) {
    error
    executer {
      name
      id
      authorized
      user
    }
    id
    success
  }
}
`;


export const createYMGroupGQL = gql`
mutation createYMGroup( $input:YMGroupInput!){
  createYMGroup( input: $input) {
    success
    error
    id
    data
  }
}
`;


export const getYMGroupsByExecuterGQL = gql`
query getYMGroupsByExecuter($getYMGroupsByExecuterId: ID!) {
  getYMGroupsByExecuter(id: $getYMGroupsByExecuterId) {
    success
    id
    error
    groups {
      id
      category
      title
      data 
      status
      reservedAt
    }
  }
}
`;

export const getYMGroupByCodeGQL = gql`
query Query($code: String!) {
  getYMGroupByCode(code: $code) {
    id
    error
    group {
      category
      code
      data
      id
      owner
      reservedAt
      status
      tags
      title
    }
  }
}
`;

export const createYMTargetGQL = gql`
mutation createYMTarget( $input:YMTargetInput!){
  createYMTarget( input: $input) {
    success
    error
    id
    data
  }
}
`;


export const getYMTargetsByExecuterGQL = gql`
query Query($getYMTargetsByExecuterId: ID!) {
  getYMTargetsByExecuter(id: $getYMTargetsByExecuterId) {
    success
    id
    error
    targets {
      id
      category
      title
      data 
      status
      reservedAt
    }
  }
}
`;

export const getYMTargetsByGrGQL = gql`
query Query($gId: ID!) {
  getYMTargetsByGr(gid: $gId) {
    success
    id
    error
    targets {
      id
      category
      title
      data 
      status
      executers {
        name
      }
      reservedAt
    }
  }
}
`;

export const allYMTargetsGQL = gql`
query Query {
  allYMTargets {
    id
    category
    title
    data
    status
    executers {
      name
    }
    reservedAt
  }
}
`;

export const getYMTargetsByStatusGQL = gql`
query Query($status: Int!) {
getYMTargetsByStatus(status: $status) {
  error
  success
  targets {
    id
    category
    title
    data
    status
    executers {
      id
      name
      authorized
    }
    tasks {
      id
      data
      status
      title
    }
    group {
      id
      data
      code
      status
    }
    reservedAt
  }
}
}
`;

export const getYMTargetsByExAndStatusGQL = gql`
query Query($eId: ID! $status: Int!) {
getYMTargetsByExAndStatus(eid: $eId, status: $status) {
  error
  success
  targets {
    id
    category
    title
    data
    status
    executers {
      id
      name
      authorized
    }
    tasks {
      id
      data
      status
      title
    }
    group {
      id
      data
      code
      status
    }
    reservedAt
  }
}
}
`;

export const getYMTargetsByNameAndExAndStatusGQL = gql`
query Query($name: String! $eId: ID! $status: Int!) {
getYMTargetsByNameAndExAndStatus(name: $name, eid: $eId, status: $status) {
  error
  success
  targets {
    id
    category
    title
    data
    status
    executers {
      id
      name
      authorized
    }
    tasks {
      id
      data
      status
      title
    }
    group {
      id
      data
      code
      status
    }
    reservedAt
  }
}
}
`;

export const getYMTargetsByExAndGrGQL = gql`
query Query($eId: ID! $gId: ID!) {
  getYMTargetsByExAndGr(eid: $eId, gid: $gId) {
    success
    id
    error
    targets {
      id
      category
      title
      data 
      status
      reservedAt
    }
  }
}
`;


export const createYMTaskGQL = gql`
mutation createYMTask( $input:YMTaskInput!){
  createYMTask( input: $input) {
    success
    error
    id
    data
  }
}
`;

export const getYMTaskGQL = gql`
query getYMTask($id: ID!){ getYMTask(id:$id){
    id
    task{
      id
      category
      title
      data 
      status
    }
    success
    error
}
}
`;

export const getYMTasksByGrAndTgGQL = gql`
query Query($gId: ID! $tgId: ID!) {
  getYMTasksByGrAndTg(gid: $gId, tgid: $tgId) {
    success
    id
    error
    tasks {
      id
      category
      title
      data 
      status
    }
  }
}
`;

export const updateYMTaskGQL = gql`
mutation updateYMTask( $id: ID! $input:YMTaskInput!){
  updateYMTask( id: $id, input: $input) {
    success
    error
    id
    data
  }
}
`;

export const updateYMTargetGQL = gql`
mutation Mutation($id: ID!, $input: YMTargetInput!) {
  updateYMTarget(id: $id, input: $input) {
    data
    error
    id
    success
  }
}
`;

export const updateYMGroupGQL = gql`
mutation Mutation($id: ID!, $input: YMGroupInput!) {
  updateYMGroup(id: $id, input: $input) {
    data
    error
    id
    success
  }
}
`;

export const updateYMExecuterGQL = gql`
mutation Mutation($id: ID!, $input: YMExecuterInput!) {
  updateYMExecuter(id: $id, input: $input) {
    data
    id
    success
    error
  }
}
`;
