import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';

import { ScheduleCard } from '@app/components/examinee-dashboard/scheduleCard/ScheduleCard';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { getAllYMExecutersGQL, updateYMGroupGQL } from '@app/hocs/gql';
import { Dates } from '@app/constants/Dates';
import { getExamMonth } from '@app/constants/YMData';
import { ymDatas, ymaDatas } from '@app/hocs/cache';
import { GroupScheduleCard } from '@app/components/examinee-dashboard/scheduleCard/GroupScheduleCard';
import styled from 'styled-components';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const GroupScheduleForm = (props:any) => {
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const { t } = useTranslation();
  
  const ymData = useReactiveVar(ymDatas);

  const [dateFormatted, setDateFormated] = useState('');
  const bmonth = getExamMonth(ymData.tg.data.bdate,ymData.tg.data.epweek,ymData.tg.data.epday);

  useEffect(()=>{
    console.log('******************* GroupScheduleForm useEffect:'+ymData.update+':'+ymData.modal);
  },[ymData.update]);

  useEffect(()=>{
    setDateFormated(Dates.format(parseInt(ymData.tg.reservedAt), 'YYYY년 M월 D일 - H시 m분'));
    setCompleted(ymData.tg.group.status === 4);
  },[ymData.tg.reservedAt]);

  const [updateYMGroup, updateYMGroupRequest] = useMutation(updateYMGroupGQL , {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
    if(data.updateYMGroup.success === true ) {}
    else{console.log(data.updateYMGroup.error);}}
  });

  const updateGroupStatus = ( status:number) => {    

    updateYMGroup({variables: { 
      id: ymData.tg.group.id,
      input: {
        "status": status,
      }
    }})
    .then((result) => {
      const {data : {updateYMGroup  : {success , data , id}}} = result;
      //console.log('updateYMGroup - data:'+data)
      notificationController.info({ message: success?'변경이 완료 되었습니다.':'변경이 되지 않았습니다. 관리자에게 문의하세요.' });
      setCompleted(status === 4);
      //ymDatas({...ymData, tg:{...ymData.tg, group:{...ymData.tg.group, status:status}}});
      //setStatus(99);
    }).catch(err => {
      //console.log('login error',createUserError)
      console.log(err)
    })

  }

  const updateGroupReservedAt = ( date:any) => {    

    updateYMGroup({variables: { 
      id: ymData.tg.group.id,
      input: {
        "reservedAt": date,
        "code":ymData.tg.group.code
      }
    }})
    .then((result) => {
      const {data : {updateYMGroup  : {success , data , id}}} = result;
      //console.log('updateYMGroup - data:'+data)
      notificationController.info({ message: success?'변경이 완료 되었습니다.':'변경이 되지 않았습니다. 관리자에게 문의하세요.' });
      //ymDatas({...ymData, tg:{...ymData.tg, group:{...ymData.tg.group, reservedAt:date}}});
      //setStatus(99);
    }).catch(err => {
      //console.log('login error',createUserError)
      console.log(err)
    })

  }

  const onFinish = async (values:any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setFieldsChanged(true);
      console.log(values);
      //props.onOk();
    }, 100);
  };

  
  return (
    <BaseButtonsForm
      {...formItemLayout}
      layout="inline"
      
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      initialValues={{
        'input-number': 3,
        'checkbox-group': ['A', 'B'],
        rate: 3.5,
      }}
      footer={
        <BaseButtonsForm.Item style={{display: 'flex', justifyContent: 'flex-end', width:'100%'}}>
          <BaseSpace align='end'>
          <BaseButton type="primary" htmlType="submit" loading={isLoading} onClick={props.onOk}>
            닫기
          </BaseButton>
          </BaseSpace>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >

    <BaseRow gutter={[10,10]}>
      

      <BaseCol span={24}>
        <RowStyled gutter={[10, 10]} wrap={true}>

        <BaseCol span={24}>
          <RowStyled gutter={[10, 10]} justify={"space-between"} wrap={false}>
            <BaseButton round type="default">{ymData.tg.group.data.name}</BaseButton>
            <BaseSpace align='end'>
            <BaseButton round 
              onClick={() => {
                updateGroupStatus(completed?0:4);
              }}
              type={completed?"ghost":"primary"}>{completed?"기관 복구":"기관 평가 완료"}</BaseButton>
            <BaseButton round 
              onClick={() => {
                updateGroupStatus(5);
              }}
              type={"text"}>기관 삭제</BaseButton>
              </BaseSpace>
  
          </RowStyled>
        </BaseCol>
        
        <BaseCol span={24}>해당 기관의 예정된 전체 검사의 날짜를 변경하실 수 있습니다.</BaseCol>
        <BaseCol span={24}><GroupScheduleCard setReserved={(date:any)=>updateGroupReservedAt(date)}/></BaseCol>
        </RowStyled>
      </BaseCol>

      <BaseCol span={24}>
      </BaseCol>

    </BaseRow>
    </BaseButtonsForm>
  );
};

const RowStyled = styled(BaseRow)`
  min-height: 0rem;
`;