import React from 'react';
import { SearchFilter } from '../SearchFilter/SearchFilter';
import * as S from './SearchOverlay.styles';

interface SearchOverlayProps {
  setFilter: (filter: string) => void;
  isFilterOpen: boolean;
}

export const SearchOverlay: React.FC<SearchOverlayProps> = ({ setFilter, isFilterOpen }) => {
  return (
    <S.Menu>
      <SearchFilter isOpen={isFilterOpen} setFilter={(filter:any)=>setFilter(filter)}/>
    </S.Menu>
  );
};
