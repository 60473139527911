import React, { useEffect, useState, useCallback, useRef } from 'react';
import { BasicTableRow, ExamineeTableRow, getExamineeTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { Dates } from '@app/constants/Dates';
import { Severity, ButtonType } from '@app/interfaces/interfaces';
import { RoundButton } from '../common/RoundButton/BaseButton';
import { Modal } from 'antd';
import { ScheduleForm } from '../forms/ScheduleForm/ScheduleForm';
import { VideoForm } from '../forms/VideoForm/VideoForm';
import { BaseButton } from '../common/BaseButton/BaseButton';
import { CheckOutlined, FormOutlined, FullscreenOutlined, MinusCircleOutlined, PlusOutlined, ScheduleOutlined, SearchOutlined } from '@ant-design/icons';
import { RegistrationForm } from '../forms/RegistrationForm/RegistrationForm';
import { BaseFormTitle } from '../common/forms/components/BaseFormTitle/BaseFormTitle';
import { HeaderSearch } from '../header/components/YMHeaderSearch/HeaderSearch';
import { BasePopover } from '../common/BasePopover/BasePopover';
import { BaseCheckbox } from '../common/BaseCheckbox/BaseCheckbox';
import * as S from './ExamineeTable.styles';
import styled from 'styled-components';
import { GroupRegistrationForm } from '../forms/GroupRegistrationForm/GroupRegistrationForm';
import { Priority } from '@app/constants/enums/priorities';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { allYMTargetsGQL, createYMGroupGQL, createYMTargetGQL, createYMTaskGQL, getAllYMExecutersGQL, getYMCountGQL, getYMGroupByCodeGQL, getYMTargetsByExAndStatusGQL, getYMTargetsByNameAndExAndStatusGQL, getYMTargetsByStatusGQL, updateYMTargetGQL, updateYMTaskGQL } from '@app/hocs/gql';
import { ymDatas, ymaDatas } from '@app/hocs/cache';
import clonedeep from 'lodash.clonedeep'
import { getExamMonth, getMonth, regions } from '@app/constants/YMData';
import { GroupScheduleForm } from '../forms/ScheduleForm/GroupScheduleForm';
import { GroupAddForm } from '../forms/GroupAddForm/GroupAddForm';

export const PopoverCheckbox = styled(BaseCheckbox)`
  margin-right: 1rem;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 7,
};

export const ExamineeTable: React.FC = () => {



    /*    
  const [tableData, setTableData] = useState<{ data: ExamineeTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  */
  const [tableData, setTableData] = useState<{ data: ExamineeTableRow[]; pagination: Pagination; loading: boolean }>({data: [],
    pagination: initialPagination,
    loading: false});
  const targets = useRef<any[]>([]);  

/*tableData:{data: any[],pagination:any,loading:boolean} = {
    data: [],
    pagination: initialPagination,
    loading: false,
  };
  */

  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [state, setState] = useState({searchText:'', searchedColumn:''})
  const [status, setStatus] = useState(99);
  const ymaData = useReactiveVar(ymaDatas);
  const ymData = useReactiveVar(ymDatas);
  const [title, setTitle] = useState('');
  
  const handleTableChange = (pagination: Pagination) => {
    if( pagination.current === undefined ) return;
    tableData.pagination.current = pagination.current;
    //fetch(pagination);
  };

  const setExamineeData = (rowId: number, modalID:number ) => {
    
    if( rowId >= 0){
      const target = clonedeep( targets.current[rowId]);
      target.data = JSON.parse(targets.current[rowId].data); 
      if( target.group === null ) target.group = {data:{}};
      target.group.data = targets.current[rowId].group !== null ? JSON.parse(targets.current[rowId].group.data) :{};  
      const examiners = ymaData.eqs.map((ex:any,idx:number)=>{
        return {id:ex.id, name:ex.name, selected: target.executers.find((element:any) => element.id === ex.id) !== undefined,auth:ex.authorized}
      });
      ymDatas({...ymDatas(), tg: target, tk:target.tasks, eqid: targets.current[rowId].executers.length > 0 ?targets.current[rowId].executers[0].id:'', modal:modalID, update:(new Date()).getTime(),examiners:examiners});
    }
    else{
      const bdate = new Date().getTime();
      const target = { data: {uid:'',name:'',bdate: new Date('2021-09-17'),sex:1,epweek:40,epday:0,bmonth:0}, tasks:[], reservedAt: bdate};
      const examiners = ymaData.eqs.map((ex:any,idx:number)=>{
        return {id:ex.id, name:ex.name, selected: false,auth:ex.authorized}
      });
      ymDatas({...ymDatas(), tg: target, tk:target.tasks, eqid: ymaData.eqid, modal:modalID, update:(new Date()).getTime(),examiners:examiners});
    }
    //uData.executer = targets.current[rowId].executers[0].id;
    //uData.reservedAt = targets.current[rowId].reservedAt;
    //uData.bmonth = 0;
    //uData.exmonth = 0;
    
    //const vData = targets.current[rowId].tasks; //JSON.parse(targets.current[rowId].tasks[0].data);
    //const rDate = targets.current[rowId].reservedAt;



    //console.log(uData);
    //setVideoInfo(vData);
    //setExamInfo(rDate);
    //setExamineeInfo(uData);
  }

  const updateExaminer = () =>{
    if( ymaData.auth === '') return;
    setStatus(99);
    //console.log('updateExaminer:'+ymaData.eqid + ymaData.eqname + ymaData.auth);

    if( ymaData.auth.indexOf('admin') > 0 ){
      getYMTargetByStatusHandler({ variables: {"status": 0},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
      // pollInterval: 500
      });  
    }
    else if(ymaData.eqid !== ''){
      getYMTargetByExAndStatusHandler({ variables: {"eId":ymaData.eqid, "status": 0},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
      // pollInterval: 500
      });  
    }
  }

  const searchExaminer = ( name:string, status:number) =>{
    setStatus(99);
    setSearchText(name);
    //d console.log('updateExaminer:'+ymaData.eqid + ymaData.eqname + ymaData.auth);

    if( ymaData.auth.indexOf('admin') > 0 ){
      getYMTargetByNameAndExAndStatusHandler({ variables: {"name":name, "eId":"", "status": status},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
      // pollInterval: 500
      });  
    }
    else if(ymaData.eqid !== ''){
      getYMTargetByNameAndExAndStatusHandler({ variables: {"name":name, "eId":ymaData.eqid, "status": status},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
      // pollInterval: 500
      });  
    }
  }

  // register modal
  const [open, setOpen] = useState(false);
  const [isOpened, setOpened] = useState(false);
  //const bData = useRef<any>(null);
  const handleSchedule = (rowId: number) => {
    //d console.log('handleSchedule---------:'+rowId);
    setExamineeData(rowId, 1);
    //bData.current = {executer: targets.current[rowId].executers.length>0?targets.current[rowId].executers[0].id:'', reservedAt: targets.current[rowId].reservedAt};
    showModal();
  }
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    // update target data 
    updateTarget();
    setOpen(false);
  };

  const handleCancel = () => {
    // cancel change
    //ymDatas({...ymDatas(), tg: {...ymDatas().tg, reservedAt: bData.current.reservedAt, executers: [{id:bData.current.executer}]}}); // update cache
    setOpen(false);
  };

    // examinee modal
    const [examineeopen, setExamineeOpen] = useState(false);

    const handleExaminee = (rowId: number) => {
      //d console.log('handleExaminee---------:'+rowId);
      setTitle(rowId >=0 ?'대상자 관리':'대상자 등록하기');
      setExamineeData(rowId,4);
      showExamineeModal();
    }
    const showExamineeModal = () => {
      setExamineeOpen(true);
    };
    const handleExamineeOk = ( bNew:any) => {
      if( bNew)
        getTargetCode();
      else
        updateTarget();
  
      setExamineeOpen(false);
    };
  
    const handleExamineeCancel = () => {
      setExamineeOpen(false);
    };

  // group modal modal
  const [gaddopen, setGAddOpen] = useState(false);
  const [isGAddOpened, setGAddOpened] = useState(false);

  const handleGroupAdd = () => {
    //d console.log('handleSchedule---------:'+rowId);
    //bData.current = {executer: targets.current[rowId].executers.length>0?targets.current[rowId].executers[0].id:'', reservedAt: targets.current[rowId].reservedAt};
    showGAddModal();
  }
  const showGAddModal = () => {
    setGAddOpen(true);
  };
  const handleGAddOk = () => {
    // update target data 
    //updateTarget();
    setGAddOpen(false);
  };

  const handleGAddCancel = () => {
    // cancel change
    //ymDatas({...ymDatas(), tg: {...ymDatas().tg, reservedAt: bData.current.reservedAt, executers: [{id:bData.current.executer}]}}); // update cache
    setGAddOpen(false);
  };

  // groupschedule modal
  const [gscheduleopen, setGScheduleOpen] = useState(false);
  const [isGScheduleOpened, setGScheduleOpened] = useState(false);
  const handleGSchedule = (rowId: number) => {
    //d console.log('handleSchedule---------:'+rowId);
    setExamineeData(rowId, 2);
    //bData.current = {executer: targets.current[rowId].executers[0].id, reservedAt: targets.current[rowId].reservedAt};
    showGSModal();
  }
  const showGSModal = () => {
    setGScheduleOpen(true);
  };
  const handleGSOk = () => {
    // reload target data 
    updateExaminer();
    setGScheduleOpen(false);
  };

  const handleGSCancel = () => {
    // cancel change
    //ymDatas({...ymDatas(), tg: {...ymDatas().tg, reservedAt: bData.current.reservedAt, executers: [{id:bData.current.executer}]}}); // update cache
    setGScheduleOpen(false);
  };

  //const [examineeInfo, setExamineeInfo] = useState(null);
  //const [examInfo, setExamInfo] = useState("");
  // video modal
  //const [videoInfo, setVideoInfo] = useState(null);
  const [videoopen, setVideoOpen] = useState(false);

  const showVideoModal = () => {
    setVideoOpen(true);
  };
  const handleVideo = (rowId: number) => {
    //d console.log('handleVideo---------:'+rowId);
    showVideoModal();
    setExamineeData(rowId,3);
  }
  const handleVideoOk = () => {
    updateExaminer();
    setVideoOpen(false);

  };

  const handleVideoCancel = () => {
    setVideoOpen(false);
  };

    // minus modal
    const [minusopen, setMinusOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showMinusModal = () => {
      setMinusOpen(true);
    };
    const handleMinus = (rowId: number) => {
      //d console.log('handleExaminee---------:'+rowId);
      setExamineeData(rowId,4);
      showMinusModal();
    }
    const handleMinusOk = () => {

      setConfirmLoading(true);
      deleteTarget();
      setTimeout(() => {
          setMinusOpen(false);
          setConfirmLoading(false);
      }, 1000);
    };
  
    const handleMinusCancel = () => {
      setMinusOpen(false);
    };
  

  const handleDeleteRow = (rowId: number) => {
    return;
    /*
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
    */
  };

    /**
    
__typename:
'YMTarget'
category:
'ym_examinee'
data:
'{"uid":"T23090330","name":"이번해","bdate":"2021-09-17T00:00:00.000Z","sex":1,"epweek":38,"epday":0}'
executers:
(1) [{…}]
id:
'64f3fb0bd79bc16ef8ec45f5'
reservedAt:
'1693711115936'
status:
0
title:
'이번해'

     */
    //setTableData((tableData) => ({ ...tableData, loading: true }));

    //setTableData({ data: jData.data, pagination: jData.pagination, loading: false });

  const [ getYMTargetByStatusHandler, getYMTargetsByStatusRequest] = useLazyQuery(getYMTargetsByStatusGQL
    , {
    onCompleted: (data) => {
      if( data.getYMTargetsByStatus.targets === null ) return;

      const tgs = clonedeep( data.getYMTargetsByStatus.targets);
      ymaDatas({...ymaData, tgs: tgs});
      targets.current = tgs; 
      setStatus(9);
      //console.log('################################################')
      notificationController.success({ message: "대상자 목록을 새로 불러왔습니다."});
    }}
  )

  const [ getYMTargetByExAndStatusHandler, getYMTargetsByExAndStatusRequest] = useLazyQuery(getYMTargetsByExAndStatusGQL
    , {
    onCompleted: (data) => {
      if( data.getYMTargetsByExAndStatus.targets === null ) return;

      const tgs = clonedeep( data.getYMTargetsByExAndStatus.targets);
      ymaDatas({...ymaData, tgs: tgs});
      targets.current = tgs; 
      setStatus(9);
      //console.log('################################################')
      notificationController.success({ message: "대상자 목록을 새로 불러왔습니다."});
    }}
  )

  const [ getYMTargetByNameAndExAndStatusHandler, getYMTargetsByNameAndExAndStatusRequest] = useLazyQuery(getYMTargetsByNameAndExAndStatusGQL
    , {
    onCompleted: (data) => {
      if( data.getYMTargetsByNameAndExAndStatus.targets === null ) return;

      const tgs = clonedeep( data.getYMTargetsByNameAndExAndStatus.targets);
      ymaDatas({...ymaData, tgs: tgs});
      targets.current = tgs; 
      setStatus(9);
      //console.log('################################################')
      notificationController.success({ message: "검색 대상자 목록을 불러왔습니다.", duration:1});
    }}
  )

  const [ getAllYMExecutersHandler, getAllYMExecutesRequest] = useLazyQuery(getAllYMExecutersGQL
    , {
    onCompleted: (data) => {
      if( data.allYMExecuters === null ) return;
      //console.log('getAllYMExecutersHandler:'+data.allYMExecuters.length);
      //executers.current = data.allYMExecuters;
      ymaDatas({...ymaData, eqs: data.allYMExecuters});

    }}
  )

  const [ getYMGroupByCodeHandler, getYMGroupByCodeRequest] = useLazyQuery(getYMGroupByCodeGQL
    , {
    onCompleted: (data) => {
      if( data.getYMGroupByCode === null ) return;
      //console.log('getYMGroupByCoceHandler:'+data.getYMGroupByCode);
      if( data.getYMGroupByCode.group !== null ){
        //d console.log(data.getYMGroupByCode.id);
        // create target
        createTarget(data.getYMGroupByCode.id);
      }
      else{
        createGroup()
      }
    }}
  )

  const [createYMGroup, createYMGroupRequest] = useMutation(createYMGroupGQL , {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
    if(data.createYMGroup.success === true ) {
      //console.log('createYMGroup - data:'+data.createYMGroup.data)
      // create target
    }
    else{console.log(data.updateYMTarget.error);}}
  });


  const [createYMTarget, createYMTargetRequest] = useMutation(createYMTargetGQL , {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
    if(data.createYMTarget.success === true ) {}
    else{console.log(data.updateYMTarget.error);}}
  });

  const [ getYMCountGQLHandler, getYMCountGQLRequest] = useLazyQuery(getYMCountGQL, {
    onCompleted: (data) => {
      if(data.getYMCount.success === true ) {
        ymDatas({...ymDatas(), tg: {...ymDatas().tg, data: {...ymDatas().tg.data, uid:data.getYMCount.code+ (data.getYMCount.count<10?'0'+data.getYMCount.count:data.getYMCount.count)}}}); // update cache
        getGrouByCode()
      }
    },
    fetchPolicy: 'network-only',
  });

  const getGrouByCode = () => {
    //d console.log("ymData.gcode:"+ymData.gcode)
    getYMGroupByCodeHandler({variables: {code: ymData.gcode},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
      // pollInterval: 500
      });
  }
  const getTargetCode = () =>{
    if( ymData.tg.data.uid.substring(8,10)=== '##'){
      const gcode = ymData.tg.data.uid.substring(0,8);
      //d console.log('code:'+gcode);
      getYMCountGQLHandler({variables: {code: gcode},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
      // pollInterval: 500
      });
    }
    else
    getGrouByCode();
  }

  const createGroup = () => {
    const today = new Date()
    const owner = ymaData.eqid;
    createYMGroup({variables: { 
      "input": {
        "code": ymData.gcode,
        "title": regions[ymData.gregion]+'-'+ymData.gname,
        "category": "ym_exam",
        "owner": owner,
        "reservedAt": today,
        "data": JSON.stringify({
          "region":regions[ymData.gregion],
          "code":ymData.gregion,
          "name":ymData.gname,
          "date":1}),
      }
    }})
    .then((result) => {    
      const {data : {createYMGroup  : {success , data , id}}} = result
      createTarget(id);

    }).catch(err => {
      //console.log('login error',createUserError)
      //d console.log(err)
    })

  }

  const createTarget = (gid:any) => {    
    // gen uid 
    //d console.log('ymData.tg.data.uid:'+ymData.tg.data.uid);
    
    createYMTarget({variables: { 
      input: {
        "title": ymData.tg.data.name,
        "category": "ym_examinee",
        "owner": ymData.eqid,
        "group": gid,
        "data": JSON.stringify({
          "uid":ymData.tg.data.uid,
          "name":ymData.tg.data.name,
          "bdate":ymData.tg.data.bdate,
          "sex":ymData.tg.data.sex,
          "epweek": ymData.tg.data.epweek,
          "epday": ymData.tg.data.epday,
        }),
        "reservedAt": ymData.tg.reservedAt.toString(),
    }
    }})
    .then((result) => {
      const {data : {createYMTarget  : {success , data , id}}} = result;
      //d console.log('createYMTarget - data:'+data)
      updateExaminer();

    }).catch(err => {
      //console.log('login error',createUserError)
      //d console.log(err)
    })

  }

  const [updateYMTarget, updateYMTargetRequest] = useMutation(updateYMTargetGQL , {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
    if(data.updateYMTarget.success === true ) {}
    else{console.log(data.updateYMTarget.error);}}
  });

  const updateTarget = () => {    
    const exids = ymData.examiners.map((ex:any,idx:number)=>{
      if( ex.selected ) return ex.id;
      else return null;
    })
    updateYMTarget({variables: { 
      id: ymData.tg.id,
      input: {
        "reservedAt": ymData.tg.reservedAt.toString(),
        "executers": exids
      }
    }})
    .then((result) => {
      const {data : {updateYMTarget  : {success , data , id}}} = result;
      //d console.log('updateYMTarget - data:'+data)
      updateExaminer();

    }).catch(err => {
      //console.log('login error',createUserError)
      //d console.log(err)
    })
  }

  const deleteTarget = () => {    
    const exids = ymData.examiners.map((ex:any,idx:number)=>{
      if( ex.selected ) return ex.id;
      else return null;
    })
    updateYMTarget({variables: { 
      id: ymData.tg.id,
      input: {
        "status": 5,
      }
    }})
    .then((result) => {
      const {data : {updateYMTarget  : {success , data , id}}} = result;
      //d console.log('updateYMTarget - data:'+data)
      updateExaminer();

    }).catch(err => {
      //console.log('login error',createUserError)
      //d console.log(err)
    })
  }
  
  useEffect(() => {
    //console.log('setExamineeData------------------------')
    setTableData({data:[],
      pagination: { ...tableData.pagination, total: targets.current.length, position: ["bottomCenter"] }, loading: true});

    const cTableData:any[] = [];

    targets.current.map((target:any,idx:number)=>{
      if( target.tasks.length !== 0 ){
        let status = 0
        target.tasks.map((task:any,idx:number)=>{ status += task.status;});
        target.status = status/target.tasks.length;  
      }
      //console.log(target.title+' - target status:'+target.status+'/ group status:'+target.group.status);
      if( status === 4 && target.group.status === 4 ){
        putToTableData(cTableData, target, idx);
      } 
      else if( status === 9 && target.group.status < 4 ){
        putToTableData(cTableData, target, idx);
      }
      else if( target.status === status ){
        putToTableData(cTableData, target, idx);
      }
      else if( status === 9 ){
        putToTableData(cTableData, target, idx);
      }
    })
    setTableData({data:cTableData, pagination: { ...initialPagination, current: tableData.pagination.current, total: cTableData.length, position: ["bottomCenter"] }, loading: false});
  },[status]);  

  const putToTableData = (tableData:any, target:any, idx:number) =>{
    const statuses = ['pending','assigned','completed','deleted'];
    const oData = JSON.parse(target.data);
    const oGroup = target.group !== null ? JSON.parse(target.group.data):{region:'미정',name:'미정'};
    tableData.push( {
      key: idx,
      schedule: parseInt( target.reservedAt),
      executer: target.executers.length > 0 ? target.executers[0].name:'미정',
      type: statuses[target.status],
      status: target.status,
      name: oData.name,
      group: oData.uid,
      gregion: oGroup.region,
      gname: oGroup.name,
      gstatus: target.group.status,
      video: target.tasks,
      bdate: oData.bdate,
      address: 'New York No. 1 Lake Park',
      tags: [
        { value: '중요', priority: Priority.LOW },
        { value: '다시 검사', priority: Priority.MEDIUM },
      ],
    })
  }

  useEffect(() => {

    getAllYMExecutersHandler({ variables: {},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
      // pollInterval: 500
    });

    //updateExaminer();

  },[]);

  useEffect(() => {

    if( ymaData.eqid !== '')
      updateExaminer();

  },[ymaData.eqid]);

    //setExamineeData(targets);

    const columns: ColumnsType<ExamineeTableRow> = [
    {
      title: t('common.schedule'),
      dataIndex: 'schedule',
      render: (text: string, record: { type: any; key: number; schedule: number, executer:string}) => <>
      <BaseButton round buttontype={record.type} onClick={()=>handleSchedule(record.key)} size="small">{Dates.format(record.schedule, 'YYYY.MM.DD(ddd)')} : {record.executer}</BaseButton>
      </>,
      //<BaseButton round buttontype={record.type} onClick={()=>handleSchedule(record.key)} size="small">{Dates.format(record.schedule, 'YYYY.MM.DD(ddd) HH:mm')} : {record.executer}</BaseButton>
      sorter: (a: ExamineeTableRow, b: ExamineeTableRow) => a.schedule - b.schedule,
      /*filters: [
        { text: '앞으로 4주', value: -70000000 },
        { text: '앞으로 1주', value: -60000000 },
        { text: '오늘', value: -50000000 },
        { text: '지난 3일', value: -40000000 },
        { text: '지난 1주', value: -30000000 },
        { text: '지난 1달', value: -20000000 },
      ],
      // 
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      //onFilter: (value: string, record) => record.name.indexOf(value) === 0,
      onFilter: (value: any, record: ExamineeTableRow) => {
        const today = new Date().getTime();
        //d console.log('record.schedule ('+today+'): '+(record.schedule - today)+ ': value:'+value);
        return (record.schedule - today) < value},
      */
    },
    {
      title: t('common.name'),
      dataIndex: 'name',
      sorter: (a: ExamineeTableRow, b: ExamineeTableRow) => a.name.localeCompare(b.name),
      showSorterTooltip: true,
    },
    {
      title: t('common.age'),
      dataIndex: 'bdate',
      showSorterTooltip: true,
      render: (text:string, record:{bdate:string}) => <>{getMonth(record.bdate)} 개월</>,
    },
    {
      title: '지역 - 구',
      dataIndex: 'gregion',
      showSorterTooltip: true,
      render: (text:string, record:{gregion:string}) => <>{record.gregion}</>,
    },
    {
      title: '소속 기관',
      dataIndex: 'gname',
      render: (text:string, record:{key:number, gname:string, gstatus:number}) => <a onClick={() => handleGSchedule(record.key)}>{record.gstatus===4?'(평가완료)':''}{record.gname}</a>,
      sorter: (a: ExamineeTableRow, b: ExamineeTableRow) => a.gname.localeCompare(b.gname),
      showSorterTooltip: true,
    },
    {
      title: t('common.video'),
      dataIndex: 'video',
      width: '12%',
      render: (text: string, record: { name: string; status:number; key: number; video: any; }) => {

        let v = "https://customer-9e9lqt027etamtgx.cloudflarestream.com/6d9db3820b952319c3380e4387a3f448/watch";
        if( record.video.length > 0){
          const videos = JSON.parse(record.video[0].data);
          v = videos.video; // "https://customer-9e9lqt027etamtgx.cloudflarestream.com/6d9db3820b952319c3380e4387a3f448/watch";
          //const t = videos.thumb; //"https://customer-9e9lqt027etamtgx.cloudflarestream.com/6d9db3820b952319c3380e4387a3f448/thumbnails/thumbnail.jpg";
  
        }
        //console.log('video recod.key:'+record.key);
        
        return (
          <BaseSpace>
          {/*<BaseButton round type={(record.video.length > 0 && record.status > 1) ?"primary":"default"} size="small" onClick={() => (record.video.length > 0 && record.status > 1) ? handleVideo(record.key):{}}>
              {t('tables.video')}*/}
            <BaseButton round type={(record.video.length > 0 && record.status > 1) ?"primary":"default"} size="small" onClick={() => handleVideo(record.key)}>
              {t('tables.video')}
            </BaseButton>

          </BaseSpace>
        );
      },
    },
    {
      title: '빼기',
      dataIndex: 'actions',
      width: '12%',
      render: (text: string, record: { name: string; key: number; }) => {
        //console.log('admin recod.key:'+record.key);
        return (
          <BaseSpace>
          <BaseButton size="small" 
            type='text'
            icon={<MinusCircleOutlined />}
            onClick={() => handleMinus(record.key)}/>
          </BaseSpace>
        );
      },
    },
    /*
    {
      title: t('common.tags'),
      key: 'tags',
      dataIndex: 'tags',
      render: (tags: Tag[]) => (
        <BaseRow gutter={[10, 10]}>
          {tags.map((tag: Tag) => {
            return (
              <BaseCol key={tag.value}>
                <Status color={defineColorByPriority(tag.priority)} text={tag.value.toUpperCase()} />
              </BaseCol>
            );
          })}
        </BaseRow>
      ),
    },


      render: (text: string, record: { name: string; status:number, key: number, video: any }) => {

        let v = "https://customer-9e9lqt027etamtgx.cloudflarestream.com/6d9db3820b952319c3380e4387a3f448/watch";
        if( record.video.length > 0){
          const videos = JSON.parse(record.video[0].data);
          v = videos.video; // "https://customer-9e9lqt027etamtgx.cloudflarestream.com/6d9db3820b952319c3380e4387a3f448/watch";
          //const t = videos.thumb; //"https://customer-9e9lqt027etamtgx.cloudflarestream.com/6d9db3820b952319c3380e4387a3f448/thumbnails/thumbnail.jpg";
  
        }
        //d console.log('video recod.key:'+record.key);
        
        return (
          <BaseSpace>
            <BaseButton round type={(record.video.length > 0 && record.status > 1) ?"primary":"default"} size="small" onClick={() => (record.video.length > 0 && record.status > 1) ? handleVideo(record.key):{}}>
              {t('tables.video')}
            </BaseButton>
          </BaseSpace>
        );
      },


    */
  ];

  const query = (keyword: string, query: string) => {
    //d console.log('setQueryOption('+query+'):'+keyword);
    let qkey = 0;
    if( query === 'all') qkey = -1;
    else if( query === 'processed') qkey = 0;
    else if( query === 'completed') qkey = 4;
    else if( query === 'deleted') qkey = 5;

    searchExaminer(keyword, qkey);
  }

  const isTwoColumnsLayout = false;

  const rightSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={2} xxl={2}>
        </BaseCol>
        <BaseCol xl={13} xxl={10}>
        {t('common.examinee-dashboard')}
        </BaseCol>
        <BaseCol>
          <S.GHButton />
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={14} xxl={14}>
      <BaseSpace>
      <BaseFormTitle  style={{padding:0, paddingRight:40}}>{t('common.examinee-dashboard')}</BaseFormTitle>
      <BaseButton round type="default" buttontype="button" onClick={()=>handleExaminee(-1)} size="small" icon={<PlusOutlined />}>대상자 등록하기</BaseButton>
      <BaseButton round type="default" buttontype="button" onClick={()=>handleGroupAdd()} size="small" icon={<PlusOutlined />}>기관 추가</BaseButton>
      </BaseSpace>
      </BaseCol>
      <BaseCol lg={10} xxl={10}>
      </BaseCol>
      <BaseCol>
      </BaseCol>
    </>
  );

  const tagSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
          <HeaderSearch  query={query} />
        </BaseCol>
        <BaseCol>
          <S.GHButton />
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseSpace style={{padding:1}}>
      <BaseButton round type="default" onClick={()=>setStatus(9)} size="small" icon={status===9&&<CheckOutlined />}>전체({targets.current.length}명 / 4000명)</BaseButton>
      <BaseButton round type="default" onClick={()=>setStatus(0)} buttontype={'pending'} size="small" icon={status===0&&<CheckOutlined />}>예정 {status ===0?'('+tableData.data.length+')':''}</BaseButton>
      <BaseButton round type="default" onClick={()=>setStatus(1)} buttontype={'assigned'} size="small" icon={status===1&&<CheckOutlined />}>진행중 {status ===1?'('+tableData.data.length+')':''}</BaseButton>
      <BaseButton round type="default" onClick={()=>setStatus(2)} buttontype={'completed'} size="small" icon={status===2&&<CheckOutlined />}>완료 {status ===2?'('+tableData.data.length+')':''}</BaseButton>
      <BaseButton round type="default" onClick={()=>setStatus(4)} buttontype={'completed'} size="small" icon={status===4&&<CheckOutlined />}>기관평가완료 {status ===4?'('+tableData.data.length+')':''}</BaseButton>
      {searchText !== '' && <BaseButton round type="default" onClick={()=>{searchExaminer('', 0);}} buttontype={'completed'} size="small" >모두 보기</BaseButton>}
      </BaseSpace>
    </>
  );

/**
      <BasePopover placement="bottom" trigger="click" color="#EBEFFF" content={
         <CustomCheckboxGroup>
             <BaseCol span={24}>
               <PopoverCheckbox value="A">전체</PopoverCheckbox>
             </BaseCol>
             <BaseCol span={24}>
               <PopoverCheckbox value="B">예정</PopoverCheckbox>
               </BaseCol>
             <BaseCol span={24}>
               <PopoverCheckbox value="C">대기</PopoverCheckbox>
               </BaseCol>
             <BaseCol span={24}>
               <PopoverCheckbox value="D">완료</PopoverCheckbox>
               </BaseCol>
             <BaseCol span={24}>
               <PopoverCheckbox value="E">오늘</PopoverCheckbox>
               </BaseCol>
             <BaseCol span={24}>
               <PopoverCheckbox value="F">내일</PopoverCheckbox>
               </BaseCol>
         </CustomCheckboxGroup>
      } onOpenChange={setOpened}>
        <BaseButton round type="default" size="small" icon={<PlusOutlined />}>태그 수정</BaseButton>
      </BasePopover>

 

  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
          <HeaderSearch />
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={5} xxl={4}>
        {tagSide}
      </BaseCol>
      <BaseCol lg={5} xxl={4}>
        <BaseSpace>
          <HeaderSearch />
        </BaseSpace>
      </BaseCol>
    </>
  );
*/

  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={5} xxl={4}>
        {tagSide}
      </BaseCol>
      <BaseCol lg={5} xxl={4}>
        <BaseSpace>
        <HeaderSearch query={query} />
        </BaseSpace>
      </BaseCol>
    </>
  );

  return (<>
    <BaseRow justify="space-between" align="middle" gutter={[15, 15]}>
      {rightSide}
      </BaseRow>
    <BaseRow justify="space-between" align="middle" gutter={[15, 15]} style={{paddingBottom:10}}>
      {leftSide}
      </BaseRow>

    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={tableData.pagination}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
    />

    <Modal title={title} maskClosable={false} closable={false} maskStyle={{background: 'rgba(0, 0, 0, 0.15)',}} open={examineeopen} onOk={(bNew:any)=>handleExamineeOk(bNew)} onCancel={handleExamineeCancel} width={'56rem'} footer={null}>
      <RegistrationForm onOk={handleExamineeOk} onCancel={handleExamineeCancel}/>
    </Modal>
    <Modal title='검사 일정' maskClosable={false} closable={false} maskStyle={{background: 'rgba(0, 0, 0, 0.15)',}} open={open} onOk={handleOk} onCancel={handleCancel} width={'62rem'} footer={null}>
      <ScheduleForm onOk={handleOk} onCancel={handleCancel}/>
    </Modal>
    <Modal title='기관 설정 변경' maskClosable={false} closable={false} maskStyle={{background: 'rgba(0, 0, 0, 0.15)',}} open={gscheduleopen} onOk={handleGSOk} onCancel={handleGSCancel} width={'40rem'} footer={null}>
      <GroupScheduleForm onOk={handleGSOk} onCancel={handleGSCancel}/>
    </Modal>
    <Modal title='기관 추가' maskClosable={false} closable={false} maskStyle={{background: 'rgba(0, 0, 0, 0.15)',}} open={gaddopen} onOk={handleGAddOk} onCancel={handleGAddCancel} width={'40rem'} footer={null}>
      <GroupAddForm onOk={handleGAddOk} onCancel={handleGAddCancel}/>
    </Modal>
    <Modal title='영상 보기' maskClosable={false} closable={false} maskStyle={{background: 'rgba(0, 0, 0, 0.15)',}} open={videoopen} onOk={handleVideoOk} onCancel={handleVideoCancel} width={'62rem'} footer={null}>
      <VideoForm open={videoopen} onOk={handleVideoOk} onCancel={handleVideoCancel}/>
    </Modal>
    <Modal title="검사자" maskClosable={false} closable={false} open={minusopen} onOk={handleMinusOk} confirmLoading={confirmLoading} onCancel={handleMinusCancel}>
      <p>검사자를 목록에서 뺍니다.</p>
    </Modal>
    </>
  );  
};

/**
 * 
 import { CustomCheckboxGroup } from '../header/layouts/DesktopHeader';

export const CustomCheckboxGroup = styled(BaseCheckbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
 *         <BasePopover placement="bottom" trigger="click" color="#EBEFFF" content={
         <CustomCheckboxGroup>
             <BaseCol span={24}>
               앞으로 4주
             </BaseCol>
             <BaseCol span={24}>
               앞으로 1주
               </BaseCol>
             <BaseCol span={24}>
               오늘
               </BaseCol>
             <BaseCol span={24}>
               지난 3일
               </BaseCol>
             <BaseCol span={24}>
               지난 1주
               </BaseCol>
             <BaseCol span={24}>  const minusExaminee = (rowId: number) => {

  } 

               지난 1달
               </BaseCol>
               <BaseCol span={24}>
               선택 해제
               </BaseCol>
         </CustomCheckboxGroup>
      } onOpenChange={setOpened}>
          <BaseButton round type="default" size="small" icon={<ScheduleOutlined />}>기간 선택</BaseButton>
      </BasePopover>
    <BaseRow justify="space-between" align="middle">
      {rightSide}

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[5, 5]}>
          <BaseCol>
            <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>
              <BaseCol>
                <HeaderFullscreen />
              </BaseCol>

              <BaseCol>
                <NotificationsDropdown />
              </BaseCol>

              <BaseCol>
                <SettingsDropdown />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            <ProfileDropdown />
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
    <BaseRow justify="space-between" align="middle">
      {leftSide}
      </BaseRow>


            <BaseButton round
              type="ghost"
              size="small"
              onClick={() => {
                notificationController.info({ message: t('tables.inviteMessage', { name: record.name }) });
              }}
            >
              {t('tables.invite')}
            </BaseButton>
            <BaseButton round type="default" size="small" danger onClick={() => handleDeleteRow(record.key)}>
              {t('tables.delete')}
            </BaseButton>
 */