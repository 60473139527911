import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { categoriesList, CategoryType } from 'constants/categoriesList';
import * as S from './SearchFilter.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { Radio } from '@app/components/common/BaseRadio/BaseRadio.styles';

interface SearchFilterProps {
  setFilter: (filter: string) => void;
  isOpen: boolean;
}

export const SearchFilter: React.FC<SearchFilterProps> = ({ setFilter, isOpen}) => {

  const { t } = useTranslation();

  const filterElements = useMemo(
    () =><Radio.Group defaultValue={'processed'} onChange={(e:any) => setFilter(e.target.value)}>{
      categoriesList.map((filter, index) => (
          <Radio key={index} value={filter.name}>
            {t(filter.title)}
          </Radio>
      ))}</Radio.Group>,
    [t],
  );

  return (
    <>
      <S.FilterWrapper isOpen={isOpen}>
        <BaseRow>{filterElements}</BaseRow>
      </S.FilterWrapper>
    </>
  );
};
