import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseChart, getDefaultTooltipStyles } from '@app/components/common/charts/BaseChart';
import { hexToRGB } from '@app/utils/utils';
import { getMarkAreaData } from '@app/utils/utils';
import { ChartSeriesData } from '@app/interfaces/interfaces';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import Tree, { DataNode } from 'antd/lib/tree';
import Transfer, { TransferDirection, TransferItem } from 'antd/lib/transfer';
import { theme } from 'antd';

// https://ant.design/components/transfer
// https://codesandbox.io/s/k6lzgj?file=/demo.tsx:229-2271

interface StatisticsData {
  day: number;
  value: string | number;
}

interface UserStatistics {
  name: string;
  data: StatisticsData[];
}

interface ScreeningsChartProps {
  firstUser?: UserStatistics;
  secondUser?: UserStatistics;
}

interface TreeTransferProps {
  dataSource: DataNode[];
  targetKeys: string[];
  onChange: (targetKeys: string[], direction: TransferDirection, moveKeys: string[]) => void;
}

// Customize Table Transfer
const isChecked = (selectedKeys: (string | number)[], eventKey: string | number) =>
  selectedKeys.includes(eventKey);

const generateTree = (treeNodes: DataNode[] = [], checkedKeys: string[] = []): DataNode[] =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    disabled: checkedKeys.includes(props.key as string),
    children: generateTree(children, checkedKeys),
  }));

const TreeTransfer = ({ dataSource, targetKeys, ...restProps }: TreeTransferProps) => {
  //const { token } = theme.useToken();

  const transferDataSource: TransferItem[] = [];
  function flatten(list: DataNode[] = []) {
    list.forEach((item) => {
      transferDataSource.push(item as TransferItem);
      flatten(item.children);
    });
  }
  flatten(dataSource);

  
  return (
    <Transfer
      {...restProps}
      targetKeys={targetKeys}
      dataSource={transferDataSource}
      className="tree-transfer"
      render={(item) => item.title!}
      showSelectAll={false}
    >
      {({ direction, onItemSelect, selectedKeys }) => {
        if (direction === 'left') {
          const checkedKeys = [...selectedKeys, ...targetKeys];
          return (
            <div style={{ padding: 10 }}>
              <Tree
                blockNode
                checkable
                checkStrictly
                defaultExpandAll
                checkedKeys={checkedKeys}
                treeData={generateTree(dataSource, targetKeys)}
                onCheck={(_, { node: { key } }) => {
                  onItemSelect(key as string, !isChecked(checkedKeys, key));
                }}
                onSelect={(_, { node: { key } }) => {
                  onItemSelect(key as string, !isChecked(checkedKeys, key));
                }}
              />
            </div>
          );
        }
      }}
    </Transfer>
  );
};


const treeData: DataNode[] = [
  { key: '0-0', title: '선생님 1' },
  {
    key: '0-1',
    title: '선생님 2',
    children: [
      { key: '0-1-0', title: '아동 1' },
      { key: '0-1-1', title: '아동 2' },
    ],
  },
  { key: '0-2', title: '선생님 3' },
  { key: '0-3', title: '선생님 4' },
  { key: '0-4', title: '선생님 5' },
];

export const ScheduleChart: React.FC<ScreeningsChartProps> = ({ firstUser, secondUser }) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const { t } = useTranslation();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const onChange = (keys: string[]) => {
    setTargetKeys(keys);
  };
  
  return <TreeTransfer dataSource={treeData} targetKeys={targetKeys} onChange={onChange} />;
};
