import { LAYOUT, media } from "@app/styles/themes/constants";
import styled from "styled-components";
import { BaseCol } from "../common/BaseCol/BaseCol";
import { GitHubButton } from "../header/components/GithubButton/GitHubButton";

export const SearchColumn = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`;


export const GHButton = styled(GitHubButton)`
  display: none;

  @media only screen and ${media.lg} {
    display: block;
  }
`;
