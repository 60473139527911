export type CategoryType = 'all' | 'processed' | 'completed' | 'deleted' | 'data tables' | 'charts' | 'forms' | 'auth' | 'maps' | 'apps';

interface Category {
  name: CategoryType;
  title: string;
}

export const categoriesList: Category[] = [
  /*
  {
    name: 'all',
    title: '전체',
  },*/
  {
    name: 'processed',
    title: '진행',
  },
  {
    name: 'completed',
    title: '종료',
  },
  {
    name: 'deleted',
    title: '삭제',
  },
];
