import React from 'react';
import * as S from './References.styles';
import { FacebookOutlined, GithubOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>
        <a href="https://" target="_blank" rel="noreferrer">
          서울아이발달지원센터{' '}
        </a>
        in 2023 &copy;. serviced by{' '}
          mbisquare
      </S.Text>
      <S.Icons>
      </S.Icons>
    </S.ReferencesWrapper>
  );
};
