import React, { useEffect, useRef } from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { userDatas, ymDatas, ymaDatas } from '@app/hocs/cache';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { createYMExecuterGQL, getFlowGQL, getUserByToken, getYMExecuterGQL } from '@app/hocs/gql';
import { NavLink, useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';

export const ProfileDropdown: React.FC = () => {
  const navigate = useNavigate();

  const { isTablet } = useResponsive();
  const userData = useReactiveVar(userDatas);
  const ymData = useReactiveVar(ymDatas);
  const [name, setName] = React.useState('');
  const useToken = useRef('');

  
  useEffect(() => {
    //d console.log('------------------userData.token:'+userData.token);
    setName(ymData.eqname)
  },[userData.uid]);

  const [ getFlowGQLHandler, getFlowGQLRequest] = useLazyQuery(getFlowGQL, {
    onCompleted: (data) => {
      if(data.getFlow.success === true && data.getFlow.flow.cards.length > 0) {
        data.getFlow.flow.cards.map( async(card:any)=>{
          if( card.title === 'User'){
              userDatas({...userDatas(), nickname: userData.data.user !== undefined?userData.data.user.name:card.subtitle});
              useToken.current = userData.token;
              getExecuterHandler({variables: {uid: userData.uid},notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only'
                // pollInterval: 500
              });
          }
        })           
      }
    },
    fetchPolicy: 'network-only',
  });

  const [ getUserByTokenHandler, getUserByTokenRequest] = useLazyQuery(getUserByToken, {
    onCompleted: (data) => {
      if( data.getUserByToken.id === null ) return;
      //d console.log('set uid by token:'+data.getUserByToken.id);
      localStorage.setItem('userID',data.getUserByToken.id); 

      //userDatas({...userDatas(), uid:data.getUserByToken.id, fid:data.getUserByToken.user.flowid});
      //d console.log( data.getUserByToken.user.id +':' +data.getUserByToken.user.username);
      const index = localStorage.getItem('index') as string;
      userDatas({...userDatas(), uid:data.getUserByToken.id, uname:data.getUserByToken.user.username, index: index, fid:data.getUserByToken.user.flowid});
      getFlowGQLHandler({variables: {id: data.getUserByToken.user.flowid},});
    }}
  )

  const getAuthInfo = () => {

    const token = userData.token;
    //const tokenKeeped = localStorage.getItem('userToken');

    //if( tokenKeeped !== null && token === '' ) token = tokenKeeped;
    //console.log('token:'+token);
    if( token !== '' && token !== null && token !== useToken.current){
      //d console.log('getAuthInfo ---------'+token);
      useToken.current = token;
      getUserByTokenHandler({
        variables: {token: token},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
        // pollInterval: 500
      });
    }
  }

  useEffect(() => {
    //d console.log('init');
    //getAuthInfo();
  },[]);

  useEffect(() => {
    getAuthInfo();
  },[ userData.token]);

  const [createExecuterHandler , createExecuterRequest] = useMutation(createYMExecuterGQL , {});

  const [getExecuterHandler, getExecuterRequest] = useLazyQuery(getYMExecuterGQL, {
      onCompleted: (data) => {
        if( data === undefined) return;
        if( data.getYMExecuter.success === false && userData.uid.length > 15){
          // create Executer
          createExecuterHandler({variables: { 
              "input": {
                  "user": userData.uid,
                  "name": userData.nickname,
                  "category": "exam",
                  "data": JSON.stringify({})
                }
            }})
            .then((result) => {
            
              const {data : {createYMExecuter  : {success , executer , id}}} = result;
              if( executer.authorized !== '' ) {
                
                ymDatas({...ymDatas(), eqid: executer.id, eqname: executer.name });
                ymaDatas({...ymaDatas(), eqid: executer.id, eqname: executer.name, auth: executer.authorized });
                //d console.log('name:'+ymDatas().eqname);
                setName(ymDatas().eqname);  
              }
              else
                navigate('/auth/login');

            }).catch(err => {
              //console.log('login error',createUserError)
              //d console.log(err)
            })
        } 
        else{
          if( data.getYMExecuter.executer !== null ) {
            ymDatas({...ymDatas(), eqid: data.getYMExecuter.executer.id, eqname: data.getYMExecuter.executer.name });
            ymaDatas({...ymaDatas(), eqid: data.getYMExecuter.executer.id, eqname: data.getYMExecuter.executer.name, auth: data.getYMExecuter.executer.authorized });
            //d console.log('name:'+ymDatas().eqname);
            setName(ymDatas().eqname);  
            navigate('/member/examinee-dashboard')
          }
          else{
            navigate('/auth/login');
            notificationController.warning({ message: "관리자에게 사용 승인을 요청해 주세요.", duration: 10 });

            //ymDatas({eqid:'', eqname:'', gid:'', gdate:0, gregion:'', gcode:'A', gname:'', tg: {}, tgid:'', tgname:'', tkid: '', tk: {}, tgidx:0, update:0, modal:0, examiners:[]});
            //ymaDatas({eqs:{}, calendar:{}, tgs:{},eqid:'', eqname:''});
          }
        }
      }
    }
  );

  return userData.uid ? (
    <BasePopover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <BaseCol>
          <BaseAvatar  alt="User" shape="circle" size={40} />
        </BaseCol>
        {isTablet && (
          <BaseCol>
            <span>{name}</span>
          </BaseCol>
        )}
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
