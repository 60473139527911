export const isLocal = () => {
  return (window.location.host.indexOf('localhost') !== -1)
}

export const taskSize = [0,0,0,4,4,4,4,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2];
export const statusStyle = [['예정','#C50000','#FFF8EB'],['진행','#1A8BAE','#D6FEF3'],['완료','#556AEB','#EBEFFF']];

export const getMonth = (birthdate:any) => {
  const now = new Date();
  const date = new Date(birthdate); 

  let year = now.getFullYear() - date.getFullYear();
  let month = now.getMonth() - date.getMonth();
  let day = now.getDate() - date.getDate();
  if( day < 0 ){ month--; day += 30; }
  if( month < 0 ){ year--; month += 12; }
  return year*12 + month;
/*
    const now = new Date();
    const date = new Date(birthdate); 
  
    const millisecondsDiff = now.getTime() - date.getTime();
    const dday = millisecondsDiff/(1000*60*60*24)|0;
    return dday/30.417|0;
*/
}

export const getExamMonth = (birthdate:any, epWeek:any, epDay:any) => {

  const now = new Date();
  const date = new Date(birthdate); 

  let year = now.getFullYear() - date.getFullYear();
  let month = now.getMonth() - date.getMonth();
  let day = now.getDate() - date.getDate();
  if( day < 0 ){ month--; day += 30; }
  if( month < 0 ){ year--; month += 12; }

  let ep = 0;
  if(epWeek < 37 ){
    ep = (39 - epWeek)*7+(7-epDay);
    const mep = ep/30|0;
    const dep = ep - mep*30;
    day = day - dep;
    if(day < 0){ month--; day += 30; }
    month = month - mep;
  }
  return year*12 + month;
  
/*  
  const diffyear = dday/365|0;
  const diffmonth = (dday - diffyear*365)/30|0;
  const diffday = dday - diffyear*365 - diffmonth*30;
*/
}

export const getAgeExamMonth = (birthdate:any, epWeek:any, epDay:any) => {
  let ep = 0;
  if(epWeek < 37 )
      ep = (39 - epWeek)*7+(7-epDay);

  const now = new Date();
  const date = new Date(birthdate); 

  const millisecondsDiff = now.getTime() - date.getTime();
  const dday = millisecondsDiff/(1000*60*60*24)|0;

  const diffDays = dday - ep;

  return diffDays/30.417|0;
  
/*  
  const diffyear = dday/365|0;
  const diffmonth = (dday - diffyear*365)/30|0;
  const diffday = dday - diffyear*365 - diffmonth*30;
*/
}

//export const regions = {"01":"강남구","02":"영등포구"};

type tRegions = {
  [key: string]: string
}

export const regions:tRegions = {
"A":"강남구",
"B":"강동구",
"C":"강북구",
"D":"강서구",
"E":"관악구",
"F":"광진구",
"G":"구로구",
"H":"금천구",
"I":"노원구",
"J":"도봉구",
"K":"동대문구",
"L":"동작구",
"M":"마포구",
"N":"서대문구",
"P":"성동구",
"Q":"성북구",
"R":"송파구",
"S":"양천구",
"T":"영등포구",
"U":"용산구",
"V":"은평구",
"W":"종로구",
"X":"중구",
"Y":"중랑구",
"Z":"서초구",
};